/**
 * ⚠️ **Important Notice** ⚠️
 *
 * **Synchronization Required**: When making any modifications to the structure of the
 * `ACCESS_TO_THE_COMPONENT` object or the `AccessKey` enum, it is crucial to synchronize
 * these changes with the backend. This is essential to ensure that the frontend and backend
 * data structures remain consistent and function correctly.
 *
 * **Action Required**:
 * - Communicate any updates to the backend development team.
 * - Ensure that the corresponding API or data models are updated to reflect the new structure.
 *
 * Keeping both frontend and backend in sync will help avoid potential issues and maintain
 * smooth functionality across the application.
 */

enum AccessKey {
	SHOW = "show",
	MAIN = "main",
	LOGIN = "login",
	ORDERS = "orders",
	ACCOUNTING = "accounting",
	ACCOUNTS = "accounts",
	ARCHIVES = "archives",
	REPORTS = "reports",
	REPORTS_CHARTS = "reportsCharts",
	REPORTS_ACCOUNTING = "reportsAccounting",
	CUSTOMERS = "customers",
	BLACKLIST = "blacklist",
	PREFERENCES_PAGE_NAME = "preferencesPageName",
	SETTINGS = "settings",
	SETTINGS_ORDERS = "settingsOrders",
	SETTINGS_TARIFFS = "settingsTariffs",
	SETTINGS_FINANCES = "settingsFinances",
	SETTINGS_MESSAGES = "settingsMessages",
	SETTINGS_MOBILE_APPS = "settingsMobileApps",
	SETTINGS_PARAMETERS = "settingsParameters",
	SETTINGS_MAP = "settingsMap",
	SETTINGS_ORDER_EXCHANGE = "settingsOrderExchange",
	SETTINGS_PAYMENT_SYSTEMS = "settingsPaymentSystems",
	SETTINGS_INTERFACE = "settingsInterface",
	SETTINGS_VOIP = "settingsVoip",
	EXECUTORS = "executors",
	TRANSPORT = "transport",
	USERS = "users",
	ROLES = "roles",
	CAR_PARKS = "carParks",
	SCHEDULES = "schedules",
	EXECUTOR_TEAMS = "executorTeams",

	CLIENTS = "clients",
	COUNTERPARTIES = "counterparties",
	AGENTS = "agents",
	CLIENT_GROUPS = "clientGroups",
	COUNTERPARTY_GROUPS = "counterpartyGroups",
	AGENT_GROUPS = "agentGroups",

	BLACKLIST_CUSTOMERS = "blacklistCustomers",
	BLACKLIST_EXECUTORS = "blacklistExecutors",
	BLACKLIST_TRANSPORT = "blacklistTransport",

	BANK_TRANSACTIONS = "bankTransactions",
	TRANSACTIONS = "transactions",
	RATINGS = "ratings",
	GPS_LOG = "gpsLog",
	MESSAGES = "messages",
	PUSH = "push",
	DRIVER_SHIFTS = "driverShifts",
	CHANGE_HISTORY = "changeHistory",
	CALL_HISTORY = "callsHistory",

	EXECUTORS_GENERAL = "executorsGeneral",
	EXECUTOR_BONUSES = "executorBonuses",
	CUSTOMER_BONUSES = "customerBonuses",
	BANK_ACCOUNTS = "bankAccounts",
	LOYALTY_PROGRAM = "loyaltyProgram",

	GENERAL = "general",

	CASHLESS_ORDERS = "cashlessOrders",
	CASHLESS_ORDERS_GENERAL = "cashlessOrdersGeneral",
	CASHLESS_ORDERS_PERIOD_REPORT = "cashlessOrdersPeriodReport",
	CASHLESS_ACCOUNTS_SUMMARY_REPORT = "cashlessAccountsSummaryReport",
	FUEL_DEDUCTION_ORDERS_REPORT = "fuelDeductionOrdersReport",
	AGENT_ORDERS_REPORT = "agentOrdersReport",
	ACCOUNTS_REPORT = "accountsReport",

	ORDERS_DISTRIBUTION = "ordersDistribution",
	FEED_TIME_CALCULATION = "feedTimeCalculation",

	MAIN_TARIFFS = "mainTariffs",
	AUTOMATIC_TARIFFS = "automaticTariffs",
	GENERAL_TARIFFS = "generalTariffs",
	ADDITIONAL = "additional",

	EXECUTOR_TARIFF_PLANS_2 = "executorTariffPlans2",
	CUSTOMER_TARIFF_PLANS = "customerTariffPlans",
	USER_TARIFF_PLANS = "userTariffPlans",
	EXECUTOR_PENALTIES = "executorPenalties",
	CUSTOMER_PENALTIES = "customerPenalties",
	USER_PENALTIES = "userPenalties",

	PROVIDER_SMS = "providerSms",
	TEMPLATES_SMS = "templatesSms",
	PUSH_NOTICES = "pushNotices",

	MOBILE_APP_EXECUTOR = "mobileAppExecutor",
	MOBILE_APP_CLIENT = "mobileAppClient",

	APPLICATION = "application",

	CUSTOMER = "customer",
	EXECUTOR = "executor",

	COLORS = "colors",
	FONT = "font",
	KEYBINDS = "keybinds",

	CAR_MODELS_AND_BRANDS = "settingsCarModelsAndBrands",
	CAR_CLASSES = "carClasses",
	OBJECTS = "objects",
	SECTORS = "sectors",
	PARKINGS = "parkings",
	PRICE_ZONES = "priceZones",
	SERVICES = "services",

	CONTROL_BUTTON_ADD = "controlButtonAdd",
	CONTROL_BUTTON_EDIT = "controlButtonEdit",
	CONTROL_BUTTON_DELETE = "controlButtonDelete",

	NEW_ORDER = "newOrder",
	EDIT_ORDER = "editOrder",
	REVERT_ORDER = "revertOrder",
	CLOSED_ORDER = "closeOrder",
	COPY_ORDER = "copyOrder",
	SET_ORDER_EXECUTOR = "setOrderExecutor",
	APPROVE_ORDER = "approveOrder",
	SEND_ORDER_AGAIN = "sendOrderAgain",
	RECALCULATE_ORDER = "recalculateOrder",
	RECALCULATE_ORDER_WITH_ACTUAL_RATES = "recalculateOrderWithActualRates",
	RETURN_ORDER_ON_AIR_OR_REMOVE_EXECUTOR = "returnOrderOnAirOrRemoveExecutor",
	SEND_ORDER_TO_AIR = "sendOrderToAir",
	SEND_ORDER_TO_EXECUTING = "sendOrderToExecuting",
	TOGGLE_ORDER_AIR = "toggleOrderAir",
	SET_ORDER_CALL_TO_CLIENT_BEING_REQUIRED = "setOrderCallToClientBeingRequired",
	SET_ORDER_CALL_TO_CLIENT_SUCCESS = "setOrderCallToClientSuccess",
	SET_ORDER_CALL_TO_EXECUTOR_BEING_REQUIRED = "setOrderCallToExecutorBeingRequired",
	SET_ORDER_CALL_TO_EXECUTOR_SUCCESS = "setOrderCallToExecutorSuccess",
	SHOW_CLIENT_NUMBER_TO_EXECUTOR = "showClientNumberToExecutor",
	COLOR_ORDER = "colorOrder",
	CALL_ORDER_CLIENT = "callOrderClient",
	CALL_ORDER_EXECUTOR = "callOrderExecutor",
	SMS_BUTTON = "SMSButton",
	AUTO_CALL_CLIENT_BUTTON = "AutoCallClientButton",

	WIDGET_ORDER_PARKING = "widgetOrderParkings",
	WIDGET_ORDER_EXECUTORS = "widgetOrderExecutors",
	WIDGET_ORDER_MAP = "widgetOrderMap",
	WIDGET_ORDER_ORDER_DETAILS = "widgetOrderOrderDetails",
	WIDGET_ORDER_CHAT = "widgetOrderChat",

	CALL = "call",
	SENDING_SMS = "sendingSMS",
	SENDEING_PUSH = "sendingPush",
	BALANSE = "balance",
	BONUS_BALANSE = "bonusBalance",
	IMPORT = "import",
	EXPORT = "export",
	REVERT_ORDER_TO_ARCHIVE = "revertOrderTOArchive",

	REFRESH = "refresh",
	BALANCE_CHANGE = "balanceChange",

	LIVE_ORDERS = "liveOrders",
	PRELIMINARY_ORDERS = "preliminaryOrders",
	EXECUTING_ORDERS = "executingOrders",
	ALL_ORDERS = "allOrders",
	CLOSED_ORDERS = "closedOrders",

	AFTER_ADDITIONAL_BUTTONS = "afterAdditionalButtons",
	METADATA = "metadata",
	MENU = "menu",
	PAGES = "pages",
	TABS = "tabs",
	BUTTONS = "buttons",
	WIDGETS = "widgets",
	MODALS = "modals",

	SEARCH_CAR = "searchCar",
	SEARCH_EXECUTOR = "searchExecutor",
	SEARCH_ORDER = "searchOrder",

	ADD_POINT = "addPoint",
	INSERT_POINT = "insertPoint",
	EDIT_POINT = "editPoint",
	DELETE_POINT = "deletePoint",
	MOVE_POINT_UP = "movePointUp",
	MOVE_POINT_DOWN = "movePointDown",

	POINT_CONTROL_PANEL = "pointControlPanel",
	FOOTER_PANEL = "footerPanel",
	ORDER_MODAL = "orderModal",

	SHOW_ACTIVE_CLIENT = "showActiveClient",
	SHOW_NO_ACTIVE_CLIENT = "showNoActiveClient",
	SHOW_ACTIVE_COUNTERPARTIES = "showActiveCounterparties",
	SHOW_NO_ACTIVE_COUNTERPARTIES = "showNoActiveCounterparties",
}

type AccessTranslate = {
	[key in AccessKey]: string;
};

const ACCESS_TRANSLATE: AccessTranslate = {
	[AccessKey.SHOW]: "show",
	[AccessKey.MAIN]: "routes.main",
	[AccessKey.LOGIN]: "routes.loginPage",
	[AccessKey.ORDERS]: "mainPage.orders.title",
	[AccessKey.ACCOUNTING]: "mainPage.accounting.title",
	[AccessKey.ACCOUNTS]: "mainPage.accounts.title",
	[AccessKey.ARCHIVES]: "mainPage.archives.title",
	[AccessKey.REPORTS]: "mainPage.reports.title",
	[AccessKey.REPORTS_CHARTS]: "mainPage.reports.charts.title",
	[AccessKey.REPORTS_ACCOUNTING]: "mainPage.reports.accounting.title",
	[AccessKey.CUSTOMERS]: "mainPage.customers.title",
	[AccessKey.BLACKLIST]: "mainPage.blacklist.title",
	[AccessKey.PREFERENCES_PAGE_NAME]: "mainPage.preferences.title",
	[AccessKey.SETTINGS]: "settings.title",

	[AccessKey.SETTINGS_ORDERS]: "settings.orders.title",
	[AccessKey.SETTINGS_TARIFFS]: "settings.tariffs.title",
	[AccessKey.SETTINGS_FINANCES]: "settings.finances.title",
	[AccessKey.SETTINGS_MESSAGES]: "settings.messages.title",
	[AccessKey.SETTINGS_MOBILE_APPS]: "settings.mobileApps.title",
	[AccessKey.SETTINGS_PARAMETERS]: "settings.parameters.title",
	[AccessKey.SETTINGS_MAP]: "settings.map.title",
	[AccessKey.SETTINGS_ORDER_EXCHANGE]: "settings.orderExchange.title",
	[AccessKey.SETTINGS_PAYMENT_SYSTEMS]: "settings.paymentSystems.title",
	[AccessKey.SETTINGS_INTERFACE]: "settings.interface.title",
	[AccessKey.SETTINGS_VOIP]: "settings.voip.title",

	[AccessKey.EXECUTORS]: "mainPage.accounts.executors.title",
	[AccessKey.TRANSPORT]: "mainPage.accounts.transport.title",
	[AccessKey.USERS]: "mainPage.accounts.users.title",
	[AccessKey.ROLES]: "mainPage.accounts.roles.title",
	[AccessKey.CAR_PARKS]: "mainPage.accounts.carParks.title",
	[AccessKey.SCHEDULES]: "mainPage.accounts.schedules.title",
	[AccessKey.EXECUTOR_TEAMS]: "mainPage.accounts.executorTeams.title",

	[AccessKey.CLIENTS]: "mainPage.customers.clients.title",
	[AccessKey.COUNTERPARTIES]: "mainPage.customers.counterparties.title",
	[AccessKey.AGENTS]: "mainPage.customers.agents.title",
	[AccessKey.CLIENT_GROUPS]: "mainPage.customers.clientGroups.title",
	[AccessKey.COUNTERPARTY_GROUPS]:
		"mainPage.customers.counterpartyGroups.title",
	[AccessKey.AGENT_GROUPS]: "mainPage.customers.agentGroups.title",

	[AccessKey.BLACKLIST_CUSTOMERS]: "mainPage.blacklist.customers.title",
	[AccessKey.BLACKLIST_EXECUTORS]: "mainPage.blacklist.executors.title",
	[AccessKey.BLACKLIST_TRANSPORT]: "mainPage.blacklist.transport.title",

	[AccessKey.BANK_TRANSACTIONS]: "mainPage.archives.bankTransactions.title",
	[AccessKey.TRANSACTIONS]: "mainPage.archives.transactions.title",
	[AccessKey.RATINGS]: "mainPage.archives.ratings.title",
	[AccessKey.GPS_LOG]: "mainPage.archives.gpsLog.title",
	[AccessKey.MESSAGES]: "mainPage.archives.messages.title",
	[AccessKey.PUSH]: "mainPage.archives.push.title",
	[AccessKey.DRIVER_SHIFTS]: "mainPage.archives.driverShifts.title",
	[AccessKey.CHANGE_HISTORY]: "mainPage.archives.changeHistory.title",
	[AccessKey.CALL_HISTORY]: "mainPage.archives.callsHistory.title",

	[AccessKey.EXECUTORS_GENERAL]: "mainPage.accounting.executors.title",
	[AccessKey.EXECUTOR_BONUSES]: "mainPage.accounting.executorBonuses.title",
	[AccessKey.CUSTOMER_BONUSES]: "mainPage.accounting.customerBonuses.title",
	[AccessKey.BANK_ACCOUNTS]: "mainPage.accounting.bankAccounts.title",
	[AccessKey.LOYALTY_PROGRAM]: "mainPage.accounting.loyaltyProgram.title",

	[AccessKey.GENERAL]: "mainPage.reports.charts.byOrders.title",

	[AccessKey.CASHLESS_ORDERS]:
		"mainPage.reports.accounting.cashlessOrders.title",
	[AccessKey.CASHLESS_ORDERS_GENERAL]:
		"mainPage.reports.accounting.cashlessOrdersGeneral.title",
	[AccessKey.CASHLESS_ORDERS_PERIOD_REPORT]:
		"mainPage.reports.accounting.cashlessOrdersPeriodReport.title",
	[AccessKey.CASHLESS_ACCOUNTS_SUMMARY_REPORT]:
		"mainPage.reports.accounting.cashlessAccountsSummaryReport.title",
	[AccessKey.FUEL_DEDUCTION_ORDERS_REPORT]:
		"mainPage.reports.accounting.fuelDeductionOrdersReport.title",
	[AccessKey.AGENT_ORDERS_REPORT]:
		"mainPage.reports.accounting.agentOrdersReport.title",
	[AccessKey.ACCOUNTS_REPORT]:
		"mainPage.reports.accounting.accountsReport.title",

	[AccessKey.ORDERS_DISTRIBUTION]: "settings.ordersDistribution.title",
	[AccessKey.FEED_TIME_CALCULATION]: "settings.feedTimeCalculation.title",

	[AccessKey.MAIN_TARIFFS]: "settings.tariffs.main.title",
	[AccessKey.AUTOMATIC_TARIFFS]: "settings.tariffs.auto.title",
	[AccessKey.GENERAL_TARIFFS]: "settings.tariffs.general.title",
	[AccessKey.ADDITIONAL]: "settings.tariffs.additional.title",

	[AccessKey.EXECUTOR_TARIFF_PLANS_2]:
		"settings.finances.executorTariffPlans.title",
	[AccessKey.CUSTOMER_TARIFF_PLANS]:
		"settings.finances.customerTariffPlans.title",
	[AccessKey.USER_TARIFF_PLANS]: "settings.finances.userTariffPlans.title",
	[AccessKey.EXECUTOR_PENALTIES]: "settings.finances.executorPenalties.title",
	[AccessKey.CUSTOMER_PENALTIES]: "settings.finances.customerPenalties.title",
	[AccessKey.USER_PENALTIES]: "settings.finances.userPenalties.title",

	[AccessKey.PROVIDER_SMS]: "settings.messages.providerSms.title",
	[AccessKey.TEMPLATES_SMS]: "settings.messages.templatesSms.title",
	[AccessKey.PUSH_NOTICES]: "settings.messages.pushNotices.title",

	[AccessKey.MOBILE_APP_EXECUTOR]: "settings.mobileApps.executor.title",
	[AccessKey.MOBILE_APP_CLIENT]: "settings.mobileApps.client.title",

	[AccessKey.APPLICATION]: "settings.parameters.tabs.programs.title",

	[AccessKey.CUSTOMER]: "settings.paymentSystems.customer.title",
	[AccessKey.EXECUTOR]: "settings.paymentSystems.executor.title",

	[AccessKey.COLORS]: "settings.interface.colors.title",
	[AccessKey.FONT]: "settings.interface.fonts.title",
	[AccessKey.KEYBINDS]: "settings.interface.keybinds.title",

	[AccessKey.CAR_MODELS_AND_BRANDS]: "pages.preferencesPages.navInfo.str200",
	[AccessKey.CAR_CLASSES]: "pages.preferencesPages.navInfo.str201",
	[AccessKey.OBJECTS]: "pages.preferencesPages.navInfo.str204",
	[AccessKey.SECTORS]: "pages.preferencesPages.navInfo.str205",
	[AccessKey.PARKINGS]: "pages.preferencesPages.navInfo.str206",
	[AccessKey.PRICE_ZONES]: "pages.preferencesPages.navInfo.str207",
	[AccessKey.SERVICES]: "pages.preferencesPages.navInfo.str208",

	[AccessKey.CONTROL_BUTTON_ADD]: "table.buttons.add",
	[AccessKey.CONTROL_BUTTON_EDIT]: "table.buttons.edit",
	[AccessKey.CONTROL_BUTTON_DELETE]: "table.buttons.delete",

	[AccessKey.NEW_ORDER]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str0",
	[AccessKey.EDIT_ORDER]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str1",
	[AccessKey.REVERT_ORDER]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str2",
	[AccessKey.CLOSED_ORDER]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str3",
	[AccessKey.COPY_ORDER]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str4",
	[AccessKey.SET_ORDER_EXECUTOR]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str200",
	[AccessKey.APPROVE_ORDER]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str201",
	[AccessKey.SEND_ORDER_AGAIN]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str5",
	[AccessKey.RECALCULATE_ORDER]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str6",
	[AccessKey.RECALCULATE_ORDER_WITH_ACTUAL_RATES]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str7",
	[AccessKey.RETURN_ORDER_ON_AIR_OR_REMOVE_EXECUTOR]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str202",
	[AccessKey.SEND_ORDER_TO_AIR]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str203",
	[AccessKey.SEND_ORDER_TO_EXECUTING]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str204",
	[AccessKey.TOGGLE_ORDER_AIR]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str205",
	[AccessKey.SET_ORDER_CALL_TO_CLIENT_BEING_REQUIRED]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str8",
	[AccessKey.SET_ORDER_CALL_TO_CLIENT_SUCCESS]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str9",
	[AccessKey.SET_ORDER_CALL_TO_EXECUTOR_BEING_REQUIRED]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str10",
	[AccessKey.SET_ORDER_CALL_TO_EXECUTOR_SUCCESS]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str11",
	[AccessKey.SHOW_CLIENT_NUMBER_TO_EXECUTOR]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str13",
	[AccessKey.COLOR_ORDER]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.colorButton.str0",
	[AccessKey.CALL_ORDER_CLIENT]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.callClientButton.str0",
	[AccessKey.CALL_ORDER_EXECUTOR]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.callExexcutorButton.str0",
	[AccessKey.SMS_BUTTON]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.sMSButton.str0",
	[AccessKey.AUTO_CALL_CLIENT_BUTTON]:
		"pages.mainPage.pages.orders.orderHeader.secondRow.str12",

	[AccessKey.WIDGET_ORDER_PARKING]: "orderPage.rightMenu.toggle.parkings",
	[AccessKey.WIDGET_ORDER_EXECUTORS]: "orderPage.rightMenu.toggle.executors",
	[AccessKey.WIDGET_ORDER_MAP]: "orderPage.rightMenu.toggle.map",
	[AccessKey.WIDGET_ORDER_ORDER_DETAILS]:
		"orderPage.rightMenu.toggle.orderDetails",
	[AccessKey.WIDGET_ORDER_CHAT]: "orderPage.rightMenu.toggle.chat",

	[AccessKey.CALL]: "call",
	[AccessKey.SENDING_SMS]:
		"pages.mainPage.pages.customers.tabs.clients.header.sendingSMS.str201",
	[AccessKey.SENDEING_PUSH]:
		"pages.mainPage.pages.customers.tabs.clients.header.sendingPush.str201",
	[AccessKey.BALANSE]:
		"pages.mainPage.pages.customers.tabs.clients.header.str0",
	[AccessKey.BONUS_BALANSE]:
		"pages.mainPage.pages.customers.tabs.clients.header.str1",
	[AccessKey.IMPORT]: "import",
	[AccessKey.EXPORT]: "export",
	[AccessKey.REVERT_ORDER_TO_ARCHIVE]: "change_history.actions.revert",
	[AccessKey.REFRESH]: "refresh",
	[AccessKey.BALANCE_CHANGE]: "balanceModal.str200",

	[AccessKey.LIVE_ORDERS]:
		"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str0",
	[AccessKey.PRELIMINARY_ORDERS]:
		"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str1",
	[AccessKey.EXECUTING_ORDERS]:
		"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str2",
	[AccessKey.ALL_ORDERS]:
		"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str3",
	[AccessKey.CLOSED_ORDERS]:
		"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str4",

	[AccessKey.AFTER_ADDITIONAL_BUTTONS]: "afterAdditionalButtons",

	[AccessKey.METADATA]: "metadata.title",
	[AccessKey.MENU]: "metadata.menu",
	[AccessKey.PAGES]: "metadata.pages",
	[AccessKey.TABS]: "metadata.tabs",
	[AccessKey.BUTTONS]: "metadata.buttons",
	[AccessKey.WIDGETS]: "metadata.widgets",
	[AccessKey.MODALS]: "metadata.modals",

	[AccessKey.SEARCH_CAR]: "settings.interface.keybinds.keys.searchCar",
	[AccessKey.SEARCH_EXECUTOR]:
		"settings.interface.keybinds.keys.searchExecutor",
	[AccessKey.SEARCH_ORDER]: "settings.interface.keybinds.keys.searchOrder",

	[AccessKey.ADD_POINT]: "orderPageWidgets.orderDetails.controls.str0",
	[AccessKey.INSERT_POINT]: "orderPageWidgets.orderDetails.controls.str1",
	[AccessKey.EDIT_POINT]: "orderPageWidgets.orderDetails.controls.str2",
	[AccessKey.DELETE_POINT]: "orderPageWidgets.orderDetails.controls.str3",
	[AccessKey.MOVE_POINT_UP]: "orderPageWidgets.orderDetails.controls.str4",
	[AccessKey.MOVE_POINT_DOWN]: "orderPageWidgets.orderDetails.controls.str5",

	[AccessKey.POINT_CONTROL_PANEL]: "pointControlPanel",
	[AccessKey.FOOTER_PANEL]: "footerPanel",
	[AccessKey.ORDER_MODAL]: "orderModal",

	[AccessKey.SHOW_ACTIVE_CLIENT]:
		"pages.mainPage.pages.customers.tabs.clients.header.str203",
	[AccessKey.SHOW_NO_ACTIVE_CLIENT]:
		"pages.mainPage.pages.customers.tabs.clients.header.str204",
	[AccessKey.SHOW_ACTIVE_COUNTERPARTIES]:
		"mainPage.customers.counterparties.table.showActive",
	[AccessKey.SHOW_NO_ACTIVE_COUNTERPARTIES]:
		"mainPage.customers.counterparties.table.showNoActive",
};

interface BranchProperty {
	[AccessKey.SHOW]: boolean;
}

type MetadataValue =
	| AccessKey.MENU
	| AccessKey.PAGES
	| AccessKey.TABS
	| AccessKey.BUTTONS
	| AccessKey.WIDGETS
	| AccessKey.MODALS;

interface BranchKind {
	[AccessKey.METADATA]: MetadataValue;
}

type AccessCompletedBranch =
	| AccessComponent
	| BranchProperty
	| BranchKind
	| BranchContoleBtns;

interface AccessComponent {
	[key: string]: AccessCompletedBranch | undefined;
}

type DisplayFields = {
	[key in AccessKey]?: AccessCompletedBranch;
};

/**
 * Default branch properties indicating whether a component or route should be visible.
 *
 * `BRANCH_DEFAULT` allows access to display the component or route.
 * `BRANCH_NOT_SHOW` denies access to display the component or route.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [BRANCH_DEFAULT, BRANCH_NOT_SHOW]: [BranchProperty, BranchProperty] = [
	{ [AccessKey.SHOW]: true },
	{ [AccessKey.SHOW]: false },
];

/**
 * Branch kinds representing different access levels for various types of interface elements.
 *
 * `BRANCH_DEFAULT_MENU` corresponds to the menu access level.
 * `BRANCH_DEFAULT_PAGES` corresponds to the pages access level.
 * `BRANCH_DEFAULT_TABS` corresponds to the tabs access level.
 * `BRANCH_DEFAULT_BUTTONS` corresponds to the buttons access level.
 * `BRANCH_DEFAULT_WIDGETS` corresponds to the widgets access level.
 */

const [
	BRANCH_DEFAULT_MENU,
	BRANCH_DEFAULT_PAGES,
	BRANCH_DEFAULT_TABS,
	BRANCH_DEFAULT_BUTTONS,
	BRANCH_DEFAULT_WIDGETS,
	BRANCH_DEFAULT_MODALS,
]: [BranchKind, BranchKind, BranchKind, BranchKind, BranchKind, BranchKind] = [
	{ ...BRANCH_DEFAULT, [AccessKey.METADATA]: AccessKey.MENU },
	{ ...BRANCH_DEFAULT, [AccessKey.METADATA]: AccessKey.PAGES },
	{ ...BRANCH_DEFAULT, [AccessKey.METADATA]: AccessKey.TABS },
	{ ...BRANCH_DEFAULT, [AccessKey.METADATA]: AccessKey.BUTTONS },
	{ ...BRANCH_DEFAULT, [AccessKey.METADATA]: AccessKey.WIDGETS },
	{ ...BRANCH_DEFAULT, [AccessKey.METADATA]: AccessKey.MODALS },
];

type ControlButtonKeys =
	| AccessKey.CONTROL_BUTTON_ADD
	| AccessKey.CONTROL_BUTTON_EDIT
	| AccessKey.CONTROL_BUTTON_DELETE;

type BranchContoleBtns = Record<ControlButtonKeys, BranchProperty>;

const BRANCH_CONTROL_BUTTONS = {
	[AccessKey.CONTROL_BUTTON_ADD]: BRANCH_DEFAULT_BUTTONS,
	[AccessKey.CONTROL_BUTTON_EDIT]: BRANCH_DEFAULT_BUTTONS,
	[AccessKey.CONTROL_BUTTON_DELETE]: BRANCH_DEFAULT_BUTTONS,
};

const BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS = {
	...BRANCH_CONTROL_BUTTONS,
	[AccessKey.AFTER_ADDITIONAL_BUTTONS]: BRANCH_DEFAULT_BUTTONS,
};

const BRANCH_PANEL_POINT = {
	[AccessKey.ADD_POINT]: BRANCH_DEFAULT_BUTTONS,
	[AccessKey.INSERT_POINT]: BRANCH_DEFAULT_BUTTONS,
	[AccessKey.EDIT_POINT]: BRANCH_DEFAULT_BUTTONS,
	[AccessKey.DELETE_POINT]: BRANCH_DEFAULT_BUTTONS,
	[AccessKey.MOVE_POINT_UP]: BRANCH_DEFAULT_BUTTONS,
	[AccessKey.MOVE_POINT_DOWN]: BRANCH_DEFAULT_BUTTONS,
};

const ACCESS_TO_THE_COMPONENT: DisplayFields = {
	[AccessKey.LOGIN]: BRANCH_DEFAULT_PAGES,
	[AccessKey.MAIN]: BRANCH_DEFAULT_PAGES,
	//* Menu: level 1
	[AccessKey.ORDERS]: {
		...BRANCH_DEFAULT_PAGES,
		//* Tabs
		[AccessKey.LIVE_ORDERS]: BRANCH_DEFAULT_TABS,
		[AccessKey.PRELIMINARY_ORDERS]: BRANCH_DEFAULT_TABS,
		[AccessKey.EXECUTING_ORDERS]: BRANCH_DEFAULT_TABS,
		[AccessKey.ALL_ORDERS]: BRANCH_DEFAULT_TABS,
		[AccessKey.CLOSED_ORDERS]: BRANCH_DEFAULT_TABS,
		//* Btns
		[AccessKey.NEW_ORDER]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.EDIT_ORDER]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.REVERT_ORDER]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.CLOSED_ORDER]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.COPY_ORDER]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SET_ORDER_EXECUTOR]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.APPROVE_ORDER]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SEND_ORDER_AGAIN]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.RECALCULATE_ORDER]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.RECALCULATE_ORDER_WITH_ACTUAL_RATES]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.RETURN_ORDER_ON_AIR_OR_REMOVE_EXECUTOR]:
			BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SEND_ORDER_TO_AIR]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SEND_ORDER_TO_EXECUTING]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.TOGGLE_ORDER_AIR]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SET_ORDER_CALL_TO_CLIENT_BEING_REQUIRED]:
			BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SET_ORDER_CALL_TO_CLIENT_SUCCESS]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SET_ORDER_CALL_TO_EXECUTOR_BEING_REQUIRED]:
			BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SET_ORDER_CALL_TO_EXECUTOR_SUCCESS]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SHOW_CLIENT_NUMBER_TO_EXECUTOR]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.COLOR_ORDER]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.CALL_ORDER_CLIENT]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.CALL_ORDER_EXECUTOR]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SMS_BUTTON]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.AUTO_CALL_CLIENT_BUTTON]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SEARCH_CAR]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SEARCH_EXECUTOR]: BRANCH_DEFAULT_BUTTONS,
		[AccessKey.SEARCH_ORDER]: BRANCH_DEFAULT_BUTTONS,
		//* Widget
		[AccessKey.WIDGET_ORDER_PARKING]: BRANCH_DEFAULT_WIDGETS,
		[AccessKey.WIDGET_ORDER_EXECUTORS]: BRANCH_DEFAULT_WIDGETS,
		[AccessKey.WIDGET_ORDER_MAP]: BRANCH_DEFAULT_WIDGETS,
		[AccessKey.WIDGET_ORDER_ORDER_DETAILS]: {
			...BRANCH_DEFAULT_WIDGETS,
			//* Btns
			[AccessKey.POINT_CONTROL_PANEL]: {
				...BRANCH_DEFAULT_BUTTONS,
				...BRANCH_PANEL_POINT,
			},
		},
		[AccessKey.WIDGET_ORDER_CHAT]: BRANCH_DEFAULT_WIDGETS,
		//* Mods
		[AccessKey.ORDER_MODAL]: {
			...BRANCH_DEFAULT_MODALS,
			//* Btns
			[AccessKey.POINT_CONTROL_PANEL]: {
				...BRANCH_DEFAULT_BUTTONS,
				...BRANCH_PANEL_POINT,
			},
			[AccessKey.FOOTER_PANEL]: {
				...BRANCH_DEFAULT_BUTTONS,
				[AccessKey.CLOSED_ORDER]: BRANCH_DEFAULT_BUTTONS,
				[AccessKey.COPY_ORDER]: BRANCH_DEFAULT_BUTTONS,
				[AccessKey.SMS_BUTTON]: BRANCH_DEFAULT_BUTTONS,
				[AccessKey.CALL_ORDER_CLIENT]: BRANCH_DEFAULT_BUTTONS,
				[AccessKey.CALL_ORDER_EXECUTOR]: BRANCH_DEFAULT_BUTTONS,
				[AccessKey.SHOW_CLIENT_NUMBER_TO_EXECUTOR]:
					BRANCH_DEFAULT_BUTTONS,
			},
		},
	},
	[AccessKey.ACCOUNTING]: {
		...BRANCH_DEFAULT_PAGES,
		//* Tabs
		[AccessKey.EXECUTORS_GENERAL]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			[AccessKey.REFRESH]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.BALANCE_CHANGE]: BRANCH_DEFAULT_BUTTONS,
		},
		[AccessKey.EXECUTOR_BONUSES]: BRANCH_DEFAULT_TABS,
		[AccessKey.CUSTOMER_BONUSES]: BRANCH_DEFAULT_TABS,
		[AccessKey.BANK_ACCOUNTS]: BRANCH_DEFAULT_TABS,
		[AccessKey.AGENTS]: BRANCH_DEFAULT_TABS,
		[AccessKey.LOYALTY_PROGRAM]: BRANCH_DEFAULT_TABS,
	},
	[AccessKey.ACCOUNTS]: {
		...BRANCH_DEFAULT_PAGES,
		//* Tabs
		[AccessKey.EXECUTORS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
			[AccessKey.CALL]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.SENDING_SMS]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.SENDEING_PUSH]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.BALANSE]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.BONUS_BALANSE]: BRANCH_DEFAULT_BUTTONS,
		},
		[AccessKey.TRANSPORT]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.USERS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.ROLES]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.CAR_PARKS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.SCHEDULES]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.EXECUTOR_TEAMS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
	},
	[AccessKey.ARCHIVES]: {
		...BRANCH_DEFAULT_PAGES,
		//* Tabs
		[AccessKey.ORDERS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			[AccessKey.CONTROL_BUTTON_EDIT]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.REVERT_ORDER_TO_ARCHIVE]: BRANCH_DEFAULT_BUTTONS,
		},
		[AccessKey.BANK_TRANSACTIONS]: BRANCH_DEFAULT_TABS,
		[AccessKey.TRANSACTIONS]: BRANCH_DEFAULT_TABS,
		[AccessKey.RATINGS]: BRANCH_DEFAULT_TABS,
		[AccessKey.GPS_LOG]: BRANCH_DEFAULT_TABS,
		[AccessKey.MESSAGES]: BRANCH_DEFAULT_TABS,
		[AccessKey.PUSH]: BRANCH_DEFAULT_TABS,
		[AccessKey.DRIVER_SHIFTS]: BRANCH_DEFAULT_TABS,
		[AccessKey.CHANGE_HISTORY]: BRANCH_DEFAULT_TABS,
		[AccessKey.CALL_HISTORY]: BRANCH_DEFAULT_TABS,
	},
	[AccessKey.REPORTS]: {
		...BRANCH_DEFAULT_MENU,
		//* Menu: level 2
		[AccessKey.REPORTS_CHARTS]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.GENERAL]: BRANCH_DEFAULT_TABS,
		},
		[AccessKey.REPORTS_ACCOUNTING]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.CASHLESS_ORDERS]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.CASHLESS_ORDERS_GENERAL]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.CASHLESS_ORDERS_PERIOD_REPORT]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.CASHLESS_ACCOUNTS_SUMMARY_REPORT]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.FUEL_DEDUCTION_ORDERS_REPORT]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.AGENT_ORDERS_REPORT]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.ACCOUNTS_REPORT]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
		},
	},
	[AccessKey.CUSTOMERS]: {
		...BRANCH_DEFAULT_PAGES,
		//* Tabs
		[AccessKey.CLIENTS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
			[AccessKey.SHOW_ACTIVE_CLIENT]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.SHOW_NO_ACTIVE_CLIENT]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.CALL]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.SENDING_SMS]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.SENDEING_PUSH]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.BALANSE]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.BONUS_BALANSE]: BRANCH_DEFAULT_BUTTONS,
		},
		[AccessKey.COUNTERPARTIES]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			[AccessKey.SHOW_ACTIVE_COUNTERPARTIES]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.SHOW_NO_ACTIVE_COUNTERPARTIES]: BRANCH_DEFAULT_BUTTONS,
		},
		[AccessKey.AGENTS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			[AccessKey.CALL]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.SENDING_SMS]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.SENDEING_PUSH]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.BALANSE]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.BONUS_BALANSE]: BRANCH_DEFAULT_BUTTONS,
		},
		[AccessKey.CLIENT_GROUPS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.COUNTERPARTY_GROUPS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.AGENT_GROUPS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
	},
	[AccessKey.BLACKLIST]: {
		...BRANCH_DEFAULT_PAGES,
		//* Tabs
		[AccessKey.BLACKLIST_CUSTOMERS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.BLACKLIST_EXECUTORS]: BRANCH_DEFAULT_TABS,
		[AccessKey.BLACKLIST_TRANSPORT]: BRANCH_DEFAULT_TABS,
	},
	[AccessKey.PREFERENCES_PAGE_NAME]: {
		...BRANCH_DEFAULT_PAGES,
		//* Tabs
		[AccessKey.CAR_MODELS_AND_BRANDS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			[AccessKey.CONTROL_BUTTON_ADD]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.CONTROL_BUTTON_DELETE]: BRANCH_DEFAULT_BUTTONS,
		},
		[AccessKey.CAR_CLASSES]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.COLORS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.OBJECTS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
			[AccessKey.IMPORT]: BRANCH_DEFAULT_BUTTONS,
			[AccessKey.EXPORT]: BRANCH_DEFAULT_BUTTONS,
		},
		[AccessKey.SECTORS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.PARKINGS]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.PRICE_ZONES]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
		[AccessKey.SERVICES]: {
			...BRANCH_DEFAULT_TABS,
			//* Btns
			...BRANCH_CONTROL_BUTTONS,
		},
	},
	[AccessKey.SETTINGS]: {
		...BRANCH_DEFAULT_MENU,
		//* Menu: level 2
		[AccessKey.SETTINGS_ORDERS]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.ORDERS]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.ORDERS_DISTRIBUTION]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
		},
		[AccessKey.SETTINGS_TARIFFS]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.MAIN_TARIFFS]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			},
			[AccessKey.AUTOMATIC_TARIFFS]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			},
			[AccessKey.GENERAL_TARIFFS]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			},
			[AccessKey.ADDITIONAL]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				[AccessKey.CONTROL_BUTTON_EDIT]: BRANCH_DEFAULT_BUTTONS,
			},
		},
		[AccessKey.SETTINGS_FINANCES]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.EXECUTOR_TARIFF_PLANS_2]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.CUSTOMER_TARIFF_PLANS]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.USER_TARIFF_PLANS]: BRANCH_DEFAULT_TABS,
			[AccessKey.EXECUTOR_PENALTIES]: BRANCH_DEFAULT_TABS,
			[AccessKey.CUSTOMER_PENALTIES]: BRANCH_DEFAULT_TABS,
			[AccessKey.USER_PENALTIES]: BRANCH_DEFAULT_TABS,
		},
		[AccessKey.SETTINGS_MESSAGES]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.PROVIDER_SMS]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
			[AccessKey.TEMPLATES_SMS]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			},
			[AccessKey.PUSH_NOTICES]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			},
		},
		[AccessKey.SETTINGS_MOBILE_APPS]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.MOBILE_APP_EXECUTOR]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			},
			[AccessKey.MOBILE_APP_CLIENT]: BRANCH_DEFAULT_TABS,
		},
		[AccessKey.SETTINGS_PARAMETERS]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.APPLICATION]: BRANCH_DEFAULT_TABS,
			[AccessKey.EXECUTOR]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_BUTTONS,
			},
		},
		[AccessKey.SETTINGS_MAP]: BRANCH_DEFAULT_PAGES,
		[AccessKey.SETTINGS_ORDER_EXCHANGE]: BRANCH_DEFAULT_PAGES,
		[AccessKey.SETTINGS_PAYMENT_SYSTEMS]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.CUSTOMER]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			},
			[AccessKey.EXECUTOR]: {
				...BRANCH_DEFAULT_TABS,
				//* Btns
				...BRANCH_CONTROL_AND_ADDITIONAL_BUTTONS,
			},
		},
		[AccessKey.SETTINGS_INTERFACE]: {
			...BRANCH_DEFAULT_PAGES,
			//* Tabs
			[AccessKey.COLORS]: BRANCH_DEFAULT_TABS,
			[AccessKey.FONT]: BRANCH_DEFAULT_TABS,
			[AccessKey.KEYBINDS]: BRANCH_DEFAULT_TABS,
		},
		[AccessKey.SETTINGS_VOIP]: BRANCH_DEFAULT_PAGES,
	},
};

export { AccessKey, ACCESS_TRANSLATE, ACCESS_TO_THE_COMPONENT };
export type {
	AccessTranslate,
	AccessComponent,
	DisplayFields,
	AccessCompletedBranch,
};
