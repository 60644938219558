// // ? executers interface
import { State as _DataCashlessOrders } from "./CashlessOrders/data/interface";
import { State as _DataCashlessOrdersGeneral } from "./CashlessOrdersGeneral/data/interface";
import { State as _DataCashlessOrdersPeriodReport } from "./CashlessOrdersPeriodReport/data/interface";
import { State as _DataCashlessAccountsSummaryReport } from "./CashlessAccountsSummaryReport/data/interface";

export namespace Reports {
	export namespace CashlessOrders {
		export interface DataCashlessOrders extends _DataCashlessOrders {}
	}

	export namespace CashlessOrdersGeneral {
		export interface DataCashlessOrdersGeneral
			extends _DataCashlessOrdersGeneral {}
	}

	export namespace CashlessOrdersPeriodReport {
		export interface DataCashlessOrdersPeriodReport
			extends _DataCashlessOrdersPeriodReport {}
	}

	export namespace CashlessAccountsSummaryReport {
		export interface DataCashlessAccountsSummaryReport
			extends _DataCashlessAccountsSummaryReport {}
	}

	export const ConstantReportSlice = {
		CASHLESS_ORDERS: "reports/cashlessOrders/data",
		CASHLESS_ORDERS_GENERAL: "reports/cashlessOrdersGeneral/data",
		CASHLESS_ORDERS_PERIOD_REPORT:
			"reports/cashlessOrdersPeriodReport/data",
		CASHLESS_ACCOUNTS_SUMMARY_REPORT:
			"reports/cashlessAccountsSummaryReport/data",
	};
}
