import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Executor from "../../../../../../../../../../services/Executor";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";
import useExecutorsActions from "../../../../../../../../../../hooks/useExecutorsActions";
import { sortByNumberOrString } from "../../../../../../../../../../utils/sortByNumberOrString";
import { OrderPageExecutorColumnId } from "../../../../../../../../../../redux/reducers/Orders/Executors/tableSettings";
import { getExecutorStatuses } from "../../../../../../../../../../components/OrderPageWidgets/Executors/constants";

const ExecutorsSubscribe: React.FC = () => {
	// const { executors } = useTypedSelector((state) => state.orderPageFilters);
	const { setExecutorsData, setIsSortMap } = useExecutorsActions();
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const { criteria, isSortMap } = useTypedSelector(
		(state) => state.orders.executors.filter,
	);

	const [valueLength, setValueLength] = useState<number>(0);
	const [valueMap, setValueMap] = useState<Map<string, Executor.Model[]>>(
		new Map(),
	);

	const onlineStatus = useMemo(() => {
		const online = criteria.online.includes("online");
		const offline = criteria.online.includes("offline");

		if (online && offline) return undefined;
		if (online) return true;
		if (offline) return false;
		return undefined;
	}, [criteria.online]);

	const workingStatus = useMemo(() => {
		const working = criteria.workingStatus.includes("working");
		const notWorking = criteria.workingStatus.includes("notWorking");

		if (working && notWorking) return undefined;
		if (working) return true;
		if (notWorking) return false;
		return undefined;
	}, [criteria.workingStatus]);

	const executorModelSubscribeOptions = useMemo(() => {
		const result: Executor.SubscribeOptions = {
			query: criteria.query,
			isOnline: onlineStatus,
			workingStatus,
			statuses: criteria.statuses,
			taxiServiceIds: criteria.taxiServiceIds,
		};

		// if (criteria.order?.column && criteria.order?.type) {
		// 	result.order = {
		// 		[criteria.order?.column]: criteria.order?.type.toUpperCase(),
		// 	};
		// }
		return result;
	}, [
		// criteria.order?.column,
		// criteria.order?.type,
		criteria.query,
		criteria.statuses,
		criteria.taxiServiceIds,
		onlineStatus,
		workingStatus,
	]);

	const { models } = useModelSubscribe(
		executorModelSubscribeOptions,
		Executor,
	);

	useEffect(() => {
		setValueLength(models.length);
		setValueMap(new Map());
		if (isSortMap) setIsSortMap(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [models, isSortMap]);

	const filtredExecutorModelData = useMemo(() => {
		if (criteria.isYourOrderStatus) {
			return models?.filter(
				(executor) =>
					!executor.executorToOrders.find(
						(order) =>
							order.source === "executor" && !order.closedAt,
					),
			);
		}
		return models;
	}, [criteria.isYourOrderStatus, models]);
	const EXECUTOR_STATUSES = useMemo(() => getExecutorStatuses(t), [t]);

	const getStatusData = useCallback(
		(rowData) => {
			const isWorking = rowData?.isWorking ?? false;

			const onOwnOrder =
				rowData?.executorToOrders.find(
					(order) => order.source === "executor" && !order.closedAt,
				) ?? false;

			const hasDebt = rowData?.debt ?? false;

			let status = rowData?.status;

			if (isWorking) {
				if (hasDebt) {
					if (onOwnOrder) {
						status = "ownOrderDebt";
					} else {
						status = "availableDebt";
					}
				} else if (onOwnOrder) {
					status = "ownOrder";
				}
			} else {
				status = "notWorking";
			}

			const statusData = EXECUTOR_STATUSES[status];

			return statusData;
		},
		[EXECUTOR_STATUSES],
	);

	const getStatusBackgroundColor = useCallback(
		(rowData) => {
			const online = rowData?.online ?? false;

			const statusData = getStatusData(rowData);

			return online ? statusData?.color : statusData?.offlineColor;
		},
		[getStatusData],
	);

	const getStatusFontColor = useCallback(
		(rowData) => {
			const statusData = getStatusData(rowData);

			return statusData?.fontColor;
		},
		[getStatusData],
	);

	const getStatusName = useCallback(
		(rowData) => {
			const isYourOrderStatus = rowData.executorToOrders.find(
				(order) => order.source === "executor" && !order.closedAt,
			);

			if (rowData.debt) {
				if (rowData.status === "available") {
					return EXECUTOR_STATUSES.availableDebt.label;
				}
				if (rowData.closedBy) return EXECUTOR_STATUSES.closed.label;

				if (isYourOrderStatus) {
					return EXECUTOR_STATUSES.ownOrderDebt.label;
				}

				return `${EXECUTOR_STATUSES[rowData.status].label} ${
					t("orderPageWidgets.executors.modelTable.str200") ?? ""
				}`;
			}

			if (isYourOrderStatus) {
				return EXECUTOR_STATUSES.ownOrder.label;
			}
			return EXECUTOR_STATUSES[rowData.status].label;
		},
		[t, EXECUTOR_STATUSES],
	);

	const getValueStatus = useCallback(
		(rowData) => {
			const name = getStatusName(rowData);
			const color = getStatusFontColor(rowData);
			const bg = getStatusBackgroundColor(rowData);
			return `${name}${color}${bg}`;
		},
		[getStatusBackgroundColor, getStatusFontColor, getStatusName],
	);

	const sort = useMemo(
		() => ({
			dataKey: criteria.order?.column || OrderPageExecutorColumnId.ONLINE,
			sortType: criteria.order?.type || "asc",
		}),
		[criteria.order],
	);

	const valueSort = useMemo<Executor.Model[]>(() => {
		if (!valueLength) return [];
		const key = `${sort.dataKey}_${sort.sortType}_${lang}`;
		const exist = valueMap.get(key);

		if (exist) return exist;

		if (sort.sortType === "asc") {
			if (sort.dataKey === OrderPageExecutorColumnId.ONLINE) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = `${prev.active}`;
						const nextName = `${next.active}`;
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.ALIAS) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = prev.alias;
						const nextName = next.alias;
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.CallSign) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName =
							prev.executorToCrews[0]?.crew?.car?.callSign || "";
						const nextName =
							next.executorToCrews[0]?.crew?.car?.callSign || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.EXECUTOR_GROUP) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = prev?.group?.name?.[lang] || "";
						const nextName = next?.group?.name?.[lang] || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.COMPANY) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName =
							prev?.taxiService?.company?.name?.[lang] || "";
						const nextName =
							next?.taxiService?.company?.name?.[lang] || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.TAXI_SERVICE) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName =
							prev?.taxiService?.settlement?.[lang] || "";
						const nextName =
							next?.taxiService?.settlement?.[lang] || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.STATUS) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = getValueStatus(prev) || "";
						const nextName = getValueStatus(next) || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.IS_WORKING) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = `${prev?.isWorking}` || "";
						const nextName = `${next?.isWorking}` || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}
		}

		// desc
		if (sort.sortType === "desc") {
			if (sort.dataKey === OrderPageExecutorColumnId.ONLINE) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = `${next.active}`;
						const nextName = `${prev.active}`;
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === OrderPageExecutorColumnId.ALIAS) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = next.alias;
						const nextName = prev.alias;
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.CallSign) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName =
							next.executorToCrews[0]?.crew?.car?.callSign || "";
						const nextName =
							prev.executorToCrews[0]?.crew?.car?.callSign || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.EXECUTOR_GROUP) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = next?.group?.name?.[lang] || "";
						const nextName = prev?.group?.name?.[lang] || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.COMPANY) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName =
							next?.taxiService?.company?.name?.[lang] || "";
						const nextName =
							prev?.taxiService?.company?.name?.[lang] || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.TAXI_SERVICE) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName =
							next?.taxiService?.settlement?.[lang] || "";
						const nextName =
							prev?.taxiService?.settlement?.[lang] || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.STATUS) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = getValueStatus(next) || "";
						const nextName = getValueStatus(prev) || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === OrderPageExecutorColumnId.IS_WORKING) {
				const sort = [...filtredExecutorModelData].sort(
					(prev, next) => {
						const prevName = `${next?.isWorking}` || "";
						const nextName = `${prev?.isWorking}` || "";
						return sortByNumberOrString(prevName, nextName);
					},
				);
				valueMap.set(key, sort);
				return sort;
			}
		}

		// base
		return filtredExecutorModelData;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		valueLength,
		sort.dataKey,
		sort.sortType,
		filtredExecutorModelData,
		valueMap,
		lang,
		getValueStatus,
		isSortMap,
		models,
	]);

	useEffect(() => {
		setExecutorsData(valueSort);
	}, [valueSort, setExecutorsData]);

	return null;
};

export default ExecutorsSubscribe;
