import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import JSPDF from "jspdf";
import JSZip from "jszip";
import autoTable, { RowInput, UserOptions } from "jspdf-autotable";
import { DatePicker } from "uikit";

import { DateRange } from "../../types/DataRange";
import IResponseWithItems from "../../types/IResponse";
import {
	formatDate,
	formatDistance,
	formatMoney,
	formatTimeToMinutes,
} from "../../pages/MainPage/pages/Reports/pages/AccountingReports/utils/formatForFile";
import { Base, Language } from "..";

class CounterpartyBillReport extends Base {
	static fromResponse(data: any): CounterpartyBillReport.Model {
		return data || [];
	}

	public static async getCounterpartyBillReport(
		params: CounterpartyBillReport.SearchOptions,
	): Promise<CounterpartyBillReport.GetCounterpartyBillReportResponse | null> {
		try {
			if (!params.dateRange) return null;

			const response = await this.request((prpc) =>
				prpc.theirsModel.report.getCounterpartyBillReport(params),
			);

			console.log(
				"[getCounterpartyBillReport] getCounterpartyBillReport res",
				{
					response,
					params,
				},
			);
			if (!response) return null;

			return {
				...response,
				items: response?.items?.map(this.fromResponse),
			};
		} catch (error) {
			console.log("[getCounterpartyBillReport] Error", error);

			return null;
		}
	}

	public static exportPDF = async (
		data: CounterpartyBillReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			// get width from table
			width: number;
			// get height from table
			height: number;

			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;
		},
	) => {
		if (!data?.length) return;
		const {
			tableColumn = [],
			width = 3500,
			height = 780,

			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,
		} = options;

		const totalWidth = columnStyles
			? Object.values(columnStyles).reduce(
					(sum, { cellWidth }) => sum + +(cellWidth || 0),
					0,
			  ) +
			  Object.keys(columnStyles).length * (5.1 / 3.56) +
			  0.1 / 3 // добавляем небольшие отступы
			: width; // если columnStyles нет, используйте дефолтную ширину

		const realWidth = totalWidth || width;
		const doc = new JSPDF({
			orientation: "landscape",
			format: [realWidth, height],
		});

		let currentY = 10;
		let _dateFrom;
		let _dateTo;

		const _dataForExport = data?.map((e) => e.data) ?? [];

		// data.forEach(({ taxiService, orders, dateRange, counter }) => {
		doc.setFont("Roboto-Regular", "normal");

		const { dateRange } = data[0];

		const dateFrom = formatDate({
			date: dateRange.from,
			isTitleFormat: true,
		});

		const dateTo = formatDate({
			date: dateRange.to,
			isTitleFormat: true,
		});

		if (!_dateFrom) _dateFrom = dateFrom;
		if (!_dateTo) _dateTo = dateTo;

		// const nameTab = headerTitle;

		// Получаем ширину страницы
		const pageWidth = doc.internal.pageSize.getWidth();
		const centerX = pageWidth / 2;

		const textArray = [
			`${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
		];

		// Проходим по каждой строке текста и выводим ее по центру
		textArray.forEach((text) => {
			// Получаем ширину текста
			const textWidth = doc.getTextWidth(text);

			// Вычисляем позицию для центрирования текста
			const textX = centerX - textWidth / 2;

			// Рисуем текст
			doc.text(text, textX, currentY);

			// Увеличиваем Y для следующей строки
			currentY += 10; // Например, отступ в 10 единиц между строками
		});

		const tableRows = _dataForExport.map((order) => {
			const payload: RowInput = [];
			const columnIdSet = new Set(columnIds);

			const retval: RowInput = [];

			if (columnIdSet.has(ColumnId.Erpou)) {
				retval.push({ content: `${order.edrpou}` });
			}

			if (columnIdSet.has(ColumnId.Account)) {
				retval.push({ content: `${order.checkValue}` });
			}

			if (columnIdSet.has(ColumnId.CompanyName)) {
				retval.push({ content: `${order.name}` });
			}
			if (columnIdSet.has(ColumnId.BalanceToDate)) {
				retval.push({ content: `${order.accountAmountAfter}` });
			}
			if (columnIdSet.has(ColumnId.ReplenishmentUah)) {
				retval.push({ content: `${order.replenishment ?? ""}` });
			}
			if (columnIdSet.has(ColumnId.OrderCount)) {
				retval.push({ content: `${order.orderCount}` });
			}
			if (columnIdSet.has(ColumnId.OrderAmountUah)) {
				retval.push({
					content: `${order.orderPrice}`,
				});
			}
			if (columnIdSet.has(ColumnId.BalanceFromDate)) {
				retval.push({
					content: order.accountAmountBefore ?? "",
				});
			}
			if (columnIdSet.has(ColumnId.PeriodBalanceUah)) {
				retval.push({
					content: order.replenishment ?? "",
				});
			}

			return [...payload, ...retval] as RowInput;
		});

		// Генерация таблицы с помощью autoTable
		autoTable(doc, {
			head: [tableColumn],
			body: tableRows,
			startY: currentY,
			styles: {
				fontSize: 12, // Размер шрифта
				cellPadding: 5, // Внутренние отступы
				lineWidth: 0.1, // Толщина линии границы
				lineColor: [0, 0, 0], // Цвет границы (черный)
				font: "Roboto-Regular",
				fontStyle: "normal",
			},
			columnStyles,
			headStyles: {
				fillColor: [41, 128, 185], // Цвет фона заголовка
				textColor: [255, 255, 255], // Цвет текста заголовка
				fontSize: 10,
			},
			margin: { top: 20 }, // Отступы сверху
			tableLineWidth: 0, // Толщина линии таблицы
			tableLineColor: [0, 0, 0], // Цвет линии таблицы
			theme: "grid",
		});

		currentY = doc?.lastAutoTable?.finalY;

		// const currency = _dataForExport?.[0]?.currency?.name || "";

		doc.setFont("Roboto-Regular", "normal");
		// doc.text(
		// 	[
		// 		`${textTranslations.successful}: ${counter.success.count}    ${textTranslations.unsuccessful}: ${counter.filed.count}    ${textTranslations.totalOrders}: ${counter.orders.count}`,
		// 		`${textTranslations.amount}, ${currency}: ${counter.success.amount}   ${textTranslations.amount}, ${currency}: ${counter.filed.amount}   ${textTranslations.total}, ${currency}: ${counter.orders.amount}`,
		// 	],
		// 	30,
		// 	currentY + 20,
		// );
		currentY += 50;
		// });

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}.pdf`;

		// Сохранение PDF
		doc.save(namefile);
	};

	public static exportExcel = async (
		_data: CounterpartyBillReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			width: number;
			height: number;

			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;

			dateFrom: DatePicker.Value;
			dateTo: DatePicker.Value;
		},
	) => {
		if (!_data?.length) return;

		const {
			tableColumn = [],
			width = 3500,
			height = 780,

			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,

			dateFrom,
			dateTo,
		} = options;

		const _dateFrom = formatDate({
			date: dateFrom ?? new Date(),
			isTitleFormat: true,
		});
		const _dateTo = formatDate({
			date: dateTo ?? new Date(),
			isTitleFormat: true,
		});

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}`;

		const workbook = new ExcelJS.Workbook(); // Создаем новую книгу Excel

		const clearWorksheets = () => {
			workbook.worksheets.forEach((sheet) =>
				workbook.removeWorksheet(sheet.name),
			);
		};

		// Функция для создания и добавления отчета
		const createReport = async (
			data: CounterpartyBillReport.Model.Item[],
			index: number,
		) => {
			const worksheet = workbook.addWorksheet(namefile); // Добавляем новый лист для каждого отчета

			tableColumn.forEach((colIndex, index) => {
				const cellWidth: number = +(
					columnStyles?.[index]?.cellWidth ?? 80
				); // Получаем ширину из columnStyles, если не определено - используем 80

				if (typeof cellWidth === "number" && cellWidth > 0) {
					// Устанавливаем ширину колонки
					worksheet.getColumn(index + 1).width = Math.round(
						cellWidth / 2.5,
					); // Используем индекс + 1, так как индексация начинается с 1
				} else {
					console.warn(
						`2 Width for column ${colIndex} is undefined or invalid: cellWidth`,
						cellWidth,
					);
				}
			});

			const _dataForExport = data?.map((e) => e.data) ?? [];

			const { dateRange } = data[0];

			const dateFrom = formatDate({
				date: dateRange.from,
				isTitleFormat: true,
			});
			const dateTo = formatDate({
				date: dateRange.to,
				isTitleFormat: true,
			});

			const emptyCellsHeader = Math.floor((tableColumn.length - 1) / 2);

			// Функция для добавления заголовка в Excel
			const addHeaderRow = (text: string, emptyCellsHeader: number) => {
				const headerRow = worksheet.addRow([text]); // Добавляем строку для текущего элемента массива
				headerRow.font = {
					// bold: true,
					size: 10,
					name: "Arial",
				}; // Жирный шрифт для шапки
				headerRow.alignment = {
					horizontal: "left", // Выровнено по левому краю
					vertical: "middle",
					wrapText: false, // Отключаем перенос текста
				};

				// Установка высоты строки, чтобы текст был полностью виден
				headerRow.height = 30; // Устанавливаем высоту строки, вы можете изменить значение по необходимости

				// Объединение ячеек (от текущей ячейки до 6-й вправо)
				worksheet.mergeCells(
					`A${headerRow.number}:F${headerRow.number}`,
				);

				// Выравнивание содержимого объединенной ячейки по центру
				headerRow.getCell(1).alignment = {
					horizontal: "center",
					vertical: "middle",
				};
			};

			// Массив заголовков
			const textheaderTable = [
				`${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
			];

			// Добавляем заголовки
			textheaderTable.forEach((text, index) => {
				addHeaderRow(text, emptyCellsHeader);
			});

			// Применяем стили к заголовкам
			const titleRow = worksheet.addRow(tableColumn);
			titleRow.font = {
				// bold: true,
				name: "Calibri",
				size: 11,
				color: { argb: "ffffff" },
			};
			titleRow.alignment = {
				horizontal: "center",
				vertical: "middle",
				wrapText: true,
			};
			titleRow.fill = {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "4baac6" },
			}; // Устанавливаем цвет ячейки заголовков
			titleRow.border = {
				top: { style: "thin", color: { argb: "FF000000" } },
				bottom: { style: "thin", color: { argb: "FF000000" } },
			};

			// Рассчитываем высоту строки на основе содержимого заголовка

			const maxHeight = Math.min(
				3, // Ограничение не более 3 строк
				Math.max(
					...tableColumn.map((cell) => {
						if (typeof cell === "string") {
							// Пример: вычисляем высоту, основываясь на длине текста
							return Math.ceil(cell.length / 10); // Здесь 15 — это количество символов на строку
						}
						return 1; // Минимальная высота для нестроковых значений
					}),
				),
			);

			// Устанавливаем высоту строки
			titleRow.height = maxHeight * 13; // Пример: 15 — это базовая высота строки, можно настроить по своему усмотрению

			_dataForExport.forEach((order, index) => {
				const row: (string | number | undefined | Date | null)[] = [];

				const columnIdSet = new Set(columnIds);

				const currency = /* orders?.[0]?.currency?.symbol || */ "₴";
				const currencyFormat =
					{
						"₴": "[$₴-uk-UA]",
						"₼": "[$₼-az-AZ]",
						zł: "[$zł-pl-PL]",
					}[currency] || "[$₴-uk-UA]";

				if (columnIdSet.has(ColumnId.Erpou)) row.push(order.edrpou);
				if (columnIdSet.has(ColumnId.Account))
					row.push(order.checkValue);

				if (columnIdSet.has(ColumnId.CompanyName)) row.push(order.name);
				if (columnIdSet.has(ColumnId.BalanceFromDate))
					row.push(order.accountAmountBefore); // !
				if (columnIdSet.has(ColumnId.ReplenishmentUah))
					row.push(order.replenishment ?? ""); // !
				if (columnIdSet.has(ColumnId.OrderCount))
					row.push(order.orderCount);
				if (columnIdSet.has(ColumnId.OrderAmountUah))
					row.push(order.orderPrice); // !
				if (columnIdSet.has(ColumnId.BalanceToDate))
					row.push(order.accountAmountAfter); // !
				if (columnIdSet.has(ColumnId.PeriodBalanceUah))
					row.push(order.replenishment); // !

				function isOdd(num: number): boolean {
					return num % 2 !== 0; // Проверяем, нечетное ли число
				}

				const newRow = worksheet.addRow(row);
				newRow.alignment = {
					horizontal: "center",
					vertical: "middle",
					wrapText: true,
				}; // Централизация строк
				newRow.font = { name: "Arial", size: 10 }; // Применяем стиль к строкам

				// Функция для получения индекса столбца
				const getColumnIndex = (columnId) =>
					Array.from(columnIdSet).indexOf(columnId) + 1;

				// Форматирование ячейки для AmountUah
				if (columnIdSet.has(ColumnId.BalanceFromDate)) {
					const amountCell = newRow.getCell(
						getColumnIndex(ColumnId.BalanceFromDate),
					);

					amountCell.numFmt = `#,#0.00 ${currencyFormat} ; -#,##0.00 ${currencyFormat} ; "-"?? ${currencyFormat} ; @_`;
					amountCell.alignment = { wrapText: true }; // Включаем перенос текста
				}

				// Форматирование ячейки для AmountUah
				if (columnIdSet.has(ColumnId.ReplenishmentUah)) {
					const amountCell = newRow.getCell(
						getColumnIndex(ColumnId.ReplenishmentUah),
					);

					amountCell.numFmt = `#,#0.00 ${currencyFormat} ; -#,##0.00 ${currencyFormat} ; "-"?? ${currencyFormat} ; @_`;
					amountCell.alignment = { wrapText: true }; // Включаем перенос текста
				}

				if (columnIdSet.has(ColumnId.OrderCount)) {
					const executionCell = newRow.getCell(
						getColumnIndex(ColumnId.OrderCount),
					);
					executionCell.numFmt = "0"; // Формат для целых чисел (можете настроить по необходимости)
				}

				// Форматирование ячейки для AmountUah
				if (columnIdSet.has(ColumnId.OrderAmountUah)) {
					const amountCell = newRow.getCell(
						getColumnIndex(ColumnId.OrderAmountUah),
					);

					amountCell.numFmt = `#,#0.00 ${currencyFormat} ; -#,##0.00 ${currencyFormat} ; "-"?? ${currencyFormat} ; @_`;
					amountCell.alignment = { wrapText: true }; // Включаем перенос текста
				}

				// Форматирование ячейки для AmountUah
				if (columnIdSet.has(ColumnId.BalanceToDate)) {
					const amountCell = newRow.getCell(
						getColumnIndex(ColumnId.BalanceToDate),
					);

					amountCell.numFmt = `#,#0.00 ${currencyFormat} ; -#,##0.00 ${currencyFormat} ; "-"?? ${currencyFormat} ; @_`;
					amountCell.alignment = { wrapText: true }; // Включаем перенос текста
				}

				// Форматирование ячейки для AmountUah
				if (columnIdSet.has(ColumnId.PeriodBalanceUah)) {
					const amountCell = newRow.getCell(
						getColumnIndex(ColumnId.PeriodBalanceUah),
					);

					amountCell.numFmt = `#,#0.00 ${currencyFormat} ; -#,##0.00 ${currencyFormat} ; "-"?? ${currencyFormat} ; @_`;
					amountCell.alignment = { wrapText: true }; // Включаем перенос текста
				}

				if (isOdd(index)) {
					newRow.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d9d9d9" },
					}; // Устанавливаем цвет ячейки заголовков
				}
				if (_dataForExport.length - 1 === index) {
					newRow.border = {
						bottom: {
							style: "thin",
							color: { argb: "FF000000" },
						},
					};
				}
				const maxHeight = Math.min(
					3, // Ограничение не более 3 строк
					Math.max(
						...row.map((cell) => {
							if (typeof cell === "string") {
								// Пример: вычисляем высоту, основываясь на длине текста
								return Math.ceil(cell.length / 10); // Здесь 15 — это количество символов на строку
							}
							return 1; // Минимальная высота для нестроковых значений
						}),
					),
				);

				// Устанавливаем высоту строки
				newRow.height = maxHeight * 13; // Пример: 15 — это базовая высота строки, можно настроить по своему усмотрению

				newRow.alignment = {
					horizontal: "center", // Горизонтальное центрирование
					vertical: "middle", // Вертикальное центрирование
					wrapText: true, // Перенос текста
				};
			});

			// Добавляем пустую строку для разделения между разными отчетами
			worksheet.addRow([]);
			worksheet.addRow([]);

			const buffer = await workbook.xlsx.writeBuffer();

			const blob = new Blob([buffer], {
				type: "application/octet-stream",
			});
			saveAs(blob, nameFileExcelOnly);
		};

		const nameFileExcelOnly = `${namefile}.xlsx`;

		// Сохранение файла Excel

		createReport(_data, 0);
		// const blob = new Blob([buffer], {
		// 	type: "application/octet-stream",
		// });
		// saveAs(blob, nameFileExcelOnly);
	};
}

declare namespace CounterpartyBillReport {
	type Model = CounterpartyBillReport.Model.Item;

	interface GetCounterpartyBillReportResponse
		extends IResponseWithItems<Model> {}

	interface SearchOptions {
		counterpartyIds?: number[];
		checkIds?: number[];
		taxiServiceIds?: number[];
		general?: boolean;
		dateRange: DateRange;
		lang: Language;

		allCounterparties?: boolean;
	}

	namespace Model {
		interface DataItem {
			counterpartyId: number;
			edrpou?: string;
			checkValue: string;
			name: string;
			accountAmountAfter?: number;
			replenishment: number;
			orderCount: number;
			orderPrice: number;
			accountAmountBefore?: number;
			amountPeriod: number;
		}
		interface Item {
			dateRange: DateRange;
			data: DataItem;
		}
	}
}

export default CounterpartyBillReport;
