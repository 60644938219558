import { Action, combineReducers } from "@reduxjs/toolkit";

import {
	cashlessOrders,
	reportsCashlessOrdersAction,
	StateCashlessOrders,
} from "./CashlessOrders";
import {
	cashlessOrdersGeneral,
	reportsCashlessOrdersGeneralAction,
	StateCashlessOrdersGeneral,
} from "./CashlessOrdersGeneral";
import {
	cashlessOrdersPeriodReport,
	reportsCashlessOrdersPeriodReportAction,
	StateCashlessOrdersPeriodReport,
} from "./CashlessOrdersPeriodReport";
import {
	cashlessAccountsSummaryReport,
	reportsCashlessAccountsSummaryReportAction,
	StateCashlessAccountsSummaryReport,
} from "./CashlessAccountsSummaryReport";
import { Reports } from "./interface";

export { Reports };

export type State = {
	cashlessOrders: StateCashlessOrders;
	cashlessOrdersGeneral: StateCashlessOrdersGeneral;
	cashlessOrdersPeriodReport: StateCashlessOrdersPeriodReport;
	cashlessAccountsSummaryReport: StateCashlessAccountsSummaryReport;
};

export type Actions = {
	cashlessOrders: typeof reportsCashlessOrdersAction;
	cashlessOrdersGeneral: typeof reportsCashlessOrdersGeneralAction;
	cashlessOrdersPeriodReport: typeof reportsCashlessOrdersPeriodReportAction;
	cashlessAccountsSummaryReport: typeof reportsCashlessAccountsSummaryReportAction;
};

export const reportsReducer = combineReducers<State, Action<Actions>>({
	cashlessOrders,
	cashlessOrdersGeneral,
	cashlessOrdersPeriodReport,
	cashlessAccountsSummaryReport,
});

export const reportsAction: Actions = {
	cashlessOrders: reportsCashlessOrdersAction,
	cashlessOrdersGeneral: reportsCashlessOrdersGeneralAction,
	cashlessOrdersPeriodReport: reportsCashlessOrdersPeriodReportAction,
	cashlessAccountsSummaryReport: reportsCashlessAccountsSummaryReportAction,
};

export default reportsReducer;
