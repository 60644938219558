import { useCallback, useMemo } from "react";
import { SortType } from "rsuite-table";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../../redux/store";
import Order, {
	OrderReminderStatus,
	OrderType,
} from "../../../../../../services/Order";
import { ColumnId } from "../columns";
import sortByNumberOrString from "../../../../../../utils/sortByNumberOrString";
import {
	getOrderPassengerPoints,
	stringifyPoint,
} from "../../../../../../redux/constants/OrdersPage/order";
import { MessageTemplateActions } from "../../../../../../types/MessageTemplateEnums";

// const backgroundColorByStatus = {
// 	offer: "1",
// 	executing: "2",
// 	inactive: "3",
// 	send: "4",
// 	dispatcherBGC: "5",
// 	dispatcherText: "6",
// };

const useOrderSortData = (): useOrderSortData.Props => {
	const { t } = useTranslation();
	const language = useTypedSelector(
		(state) => state.settings.map.mapLanguage,
	);

	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);
	const client = useMemo(() => t("client") || "", [t]);

	const getOrderDate = useCallback((item: Order.Model) => {
		const isPreliminary = item?.isPreliminary;

		const orderTime =
			isPreliminary && item?.orderDate
				? new Date(item.orderDate).getTime()
				: 0;
		return orderTime;
	}, []);

	const getExecutorArrivalTime = useCallback((item: Order.Model) => {
		const executorArrivalTimestamp =
			item?.executorToOrder?.[0]?.additionalFields?.arrivalTime || 0;
		const orderTime = item?.orderDate
			? new Date(item.orderDate).getTime()
			: 0;
		return Math.max(orderTime, executorArrivalTimestamp);
	}, []);

	const getTimeToDelivery = useCallback((item: Order.Model) => {
		const executorArrivalTimestamp =
			item?.executorToOrder?.[0]?.additionalFields?.arrivalTime || 0;
		const orderTime = item?.orderDate
			? new Date(item.orderDate).getTime()
			: 0;

		return executorArrivalTimestamp || orderTime;
	}, []);

	const getExecutorCallSign = useCallback((order: Order.Model) => {
		const executor =
			order?.executorToOrder && order?.executorToOrder?.length
				? order?.executorToOrder?.[0]?.executor
				: order.offer?.executor;
		const callSign = executor?.callSign || "";

		// const isDispatcher = order?.executorToOrder?.find(
		// 	(order) =>
		// 		order?.executor?.id === executor?.id &&
		// 		order?.additionalFields?.acceptedByDispatcher?.active,
		// );

		// const backgroundColorFn = (item: Order.Model, dispatcher: boolean) => {
		// 	if (item?.executorReminderStatus) {
		// 		const { status } = item.executorReminderStatus;
		// 		if (status && OrderReminderStatus.SEND === status) {
		// 			return backgroundColorByStatus.send;
		// 		}
		// 	}

		// 	if (item.executingStages?.accepted) {
		// 		return backgroundColorByStatus.executing;
		// 	}

		// 	if (
		// 		(dispatcher &&
		// 			item.executingStages?.assigned &&
		// 			!item.executingStages?.accepted) ||
		// 		(dispatcher &&
		// 			!item?.offer?.executor?.online &&
		// 			!item.executingStages?.accepted)
		// 	) {
		// 		return backgroundColorByStatus.dispatcherBGC;
		// 	}

		// 	if (item.executingStages?.assigned) {
		// 		return backgroundColorByStatus.inactive;
		// 	}

		// 	if (item.offer) {
		// 		return item.offer.executor.online
		// 			? backgroundColorByStatus.offer
		// 			: backgroundColorByStatus.inactive;
		// 	}

		// 	return "";
		// };

		// const textColorFn = (item: Order.Model, dispatcher: boolean) =>
		// 	(dispatcher && item.executingStages?.assigned) ||
		// 	(dispatcher && !item?.offer?.executor?.online)
		// 		? "7"
		// 		: "";

		// const textColor = textColorFn(order, isDispatcher);
		// const backgroundColor = backgroundColorFn(order, isDispatcher);

		// return `${callSign}_${backgroundColor}_${textColor}`;
		return callSign;
	}, []);

	const getPickupSettlement = useCallback((item: Order.Model) => {
		const points = getOrderPassengerPoints(item);
		return points?.[0]?.settlement || "";
	}, []);

	const getPickupAddress = useCallback(
		(item: Order.Model) => {
			const points = getOrderPassengerPoints(item);
			const address = points?.[0]
				? stringifyPoint(points[0], "address", pointOnMap)
				: "";
			return address;
		},
		[pointOnMap],
	);

	const getPickupSector = useCallback(
		(item: Order.Model) => {
			const points = getOrderPassengerPoints(item);
			const value = points[0]?.sector?.name?.[language] || "";
			return value;
		},
		[language],
	);

	const getPhone = useCallback((item: Order.Model) => {
		const value = item.phones?.at(-1)?.number || "";
		return value;
	}, []);

	const getOrderPrice = useCallback((item: Order.Model) => {
		const value = item?.cost?.value || 0;
		return value;
	}, []);

	const getCustomerCallStatus = useCallback((item: Order.Model) => {
		const value = item?.customerCallStatus?.status || "";
		return value;
	}, []);

	const getExecutorCallStatus = useCallback((item: Order.Model) => {
		const value = item?.executorCallStatus?.status || "";
		return value;
	}, []);

	const getOrderNumber = useCallback((item: Order.Model) => {
		const value = item?.orderNumber || "";
		return value;
	}, []);

	const getExecutingStageWithClientsTime = useCallback(
		(item: Order.Model) => {
			const value = item?.executingStages?.with_clients?.timestamp || "";
			return value;
		},
		[],
	);

	const getCarClass = useCallback(
		(item: Order.Model) => {
			const value =
				item.orderToCarClasses
					?.map((item) => item?.carClass?.name?.[language])
					.join(", ") || "";
			return value;
		},
		[language],
	);

	const getDestinationSettlement = useCallback((item: Order.Model) => {
		const points = getOrderPassengerPoints(item);
		const value = points?.at(-1)?.settlement;
		return value;
	}, []);

	const getDestinationAddress = useCallback(
		(item: Order.Model) => {
			const points = getOrderPassengerPoints(item);
			const value = points?.at(-1)
				? stringifyPoint(points.at(-1), "address", pointOnMap)
				: "";
			return value;
		},
		[pointOnMap],
	);

	const getDestinationSector = useCallback(
		(item: Order.Model) => {
			const points = getOrderPassengerPoints(item);
			const value = points?.at(-1)
				? points.at(-1)?.sector?.name?.[language]
				: "";
			return value;
		},
		[language],
	);

	const getPaymentType = useCallback((item: Order.Model) => {
		const value = item.paymentStatus?.status || "";
		return value;
	}, []);

	const getDispatcherCallSign = useCallback(
		(item: Order.Model) => {
			const data = item.closedOrderComments?.[0];
			if (data?.dispatcher)
				return `dispatcher_${data?.dispatcher?.callSign}` || "";
			if (data?.executor)
				return `executor_${data?.executor?.callSign}` || "";
			if (data?.customer) return client;
			return "";
		},
		[client],
	);

	const getStatus = useCallback((item: Order.Model) => {
		const value = item.status;
		return value || "";
	}, []);

	const getSource = useCallback((item: Order.Model) => {
		const value = item.source;
		return value || "";
	}, []);

	const getExecutingStage = useCallback((item: Order.Model) => {
		const value = item.executingStage;
		return value || "";
	}, []);

	const getCloseReason = useCallback(
		(item: Order.Model) => {
			const data = item.closedOrderComments?.[0];
			const status = data.status || "";
			let value = "";

			if (data?.dispatcher)
				value = `dispatcher_${data?.dispatcher?.callSign}` || "";
			if (data?.executor)
				value = `executor_${data?.executor?.callSign}` || "";
			if (data?.customer) value = client || "";

			return `${status}${value}`.trim();
		},
		[client],
	);

	const getTotalRideCount = useCallback((item: Order.Model) => {
		const value = item?.passengers?.[0]?.customer?.counters?.total || 0;
		return value;
	}, []);

	const getCreateOrderDate = useCallback((item: Order.Model) => {
		const value = item.createdAt ? new Date(item.createdAt).getTime() : 0;
		return value;
	}, []);

	const getCreateOrderTime = useCallback((item: Order.Model) => {
		const value = item.createdAt ? new Date(item.createdAt).getTime() : 0;
		return value;
	}, []);

	const getCustomerFullName = useCallback((item: Order.Model) => {
		const person =
			item.type === OrderType.CUSTOMER_SPONSORED
				? item.billingCustomer?.person
				: item.passengers?.[0]?.customer?.person;

		const name = person?.name || "";
		const surname = person?.surname || "";
		const fatherName = person?.fatherName || "";
		const fullName = `${surname || ""} ${name || ""} ${
			fatherName || ""
		}`.trim();

		const value = `person_${fullName || "notfound"}`;
		return value;
	}, []);

	const getCounterparty = useCallback((item: Order.Model) => {
		const value = item?.counterparty?.additionalFields?.name || "";
		return value;
	}, []);

	const getCounterpartyCheck = useCallback((item: Order.Model) => {
		const value =
			item?.checkCard?.check?.additionalFields?.checkValue || "";
		return value;
	}, []);

	const getCounterpartyCheckCard = useCallback((item: Order.Model) => {
		const value = item?.checkCard?.code?.value || "";
		return value;
	}, []);

	const getExecutorLate = useCallback((item: Order.Model) => {
		const value = item?.executingStages?.delayed?.data?.delay || "";
		return value;
	}, []);

	const getExecutingStageAcceptedTime = useCallback((item: Order.Model) => {
		const value = item.executingStages?.accepted?.timestamp || 0;
		return value;
	}, []);

	const getExecutingStageArrivedTime = useCallback((item: Order.Model) => {
		const value = item.executingStages?.arrived?.timestamp || "";
		return value;
	}, []);

	const getExecutingStageWaitingCustomerTime = useCallback(
		(item: Order.Model) => {
			const value = item.executingStages?.waiting_client?.timestamp || "";
			return value;
		},
		[],
	);

	const getSMSLateStatus = useCallback(
		(item: Order.Model) => {
			const data = item.smsMessages?.find(
				(smsMessage) =>
					smsMessage?.action?.toString() ===
					MessageTemplateActions.LATE.toString(),
			);
			const value = t(`order_sms_message_statuses.${data?.status}`);
			return value || "";
		},
		[t],
	);

	const getSMSWillBeAtStatus = useCallback(
		(item: Order.Model) => {
			const data = item.smsMessages?.find(
				(smsMessage) =>
					smsMessage?.action?.toString() ===
					MessageTemplateActions.WILL_BE_TIME.toString(),
			);

			const value = t(`order_sms_message_statuses.${data?.status}`);
			return value || "";
		},
		[t],
	);

	const getSMSCarNotFoundStatus = useCallback(
		(item: Order.Model) => {
			const data = item.smsMessages?.find(
				(smsMessage) =>
					smsMessage?.action?.toString() ===
					MessageTemplateActions.CAR_NOT_FOUND.toString(),
			);
			const value = t(`order_sms_message_statuses.${data?.status}`);
			return value || "";
		},
		[t],
	);

	const getSMSAwaitingPassengersStatus = useCallback(
		(item: Order.Model) => {
			const data = item.smsMessages?.find(
				(smsMessage) =>
					smsMessage?.action?.toString() ===
					MessageTemplateActions.OUTPUT_PASSENGERS.toString(),
			);

			const value = t(`order_sms_message_statuses.${data?.status}`);
			return value || "";
		},
		[t],
	);

	const getSMSWithPassengersStatus = useCallback(
		(item: Order.Model) => {
			const data = item.smsMessages?.find(
				(smsMessage) =>
					smsMessage?.action?.toString() ===
					MessageTemplateActions.WITH_PASSENGERS.toString(),
			);

			const value = t(`order_sms_message_statuses.${data?.status}`);
			return value || "";
		},
		[t],
	);

	const getSMSOrderCompletedStatus = useCallback(
		(item: Order.Model) => {
			const data = item.smsMessages?.find(
				(smsMessage) =>
					smsMessage?.action?.toString() ===
					MessageTemplateActions.ORDER_SUCCESSFULLY_COMPLETED.toString(),
			);

			const value = t(`order_sms_message_statuses.${data?.status}`);
			return value || "";
		},
		[t],
	);

	const getSMSExecutorSwitchedStatus = useCallback(
		(item: Order.Model) => {
			const data = item.smsMessages?.find(
				(smsMessage) =>
					smsMessage?.action?.toString() ===
					MessageTemplateActions.DRIVER_SWITCHED.toString(),
			);

			const value = t(`order_sms_message_statuses.${data?.status}`);
			return value || "";
		},
		[t],
	);

	const getCompany = useCallback(
		(item: Order.Model) => {
			const value = item.taxiService?.company?.name?.[language];
			return value || "";
		},
		[language],
	);

	const getTaxiService = useCallback(
		(item: Order.Model) => {
			const value = item.taxiService?.settlement?.[language];
			return value || "";
		},
		[language],
	);

	const getIdleTime = useCallback((item: Order.Model) => {
		const value = item?.additionalFields?.idleTimeMilliseconds;
		return value || 0;
	}, []);

	const getTariff = useCallback(
		(item: Order.Model) => {
			const value = item.rate?.name?.[language];
			return value || "";
		},
		[language],
	);

	const getServices = useCallback(
		(item: Order.Model) => {
			const value = item.orderToServices
				?.map(
					(orderToService) =>
						orderToService?.service?.name?.[language],
				)
				.join(", ");
			return value || "";
		},
		[language],
	);

	const getParameters = useCallback(
		(item: Order.Model) => {
			const rateSettings = item.additionalFields.rateSettings || {};
			const rateSettingTypes = Object.keys(rateSettings) || [];
			const value = rateSettingTypes
				?.filter((rateSettingType) => rateSettings?.[rateSettingType])
				.map(
					(rateSettingType) =>
						t(`order_rate_setting.${rateSettingType}`) ?? "",
				)
				.join(", ");

			return value || "";
		},
		[t],
	);

	const getDiscount = useCallback((item: Order.Model) => {
		const value = item.additionalFields?.discount?.active;
		return `${value}`;
	}, []);

	const getTransportationType = useCallback(
		(item: Order.Model) => {
			const type = item.additionalFields?.transportationType;
			const value = t([
				`orderPage.order_form.transportation_types.${type}`,
				type,
			]);
			return value || 0;
		},
		[t],
	);

	const getRideNumber = useCallback((item: Order.Model) => {
		const value = item.passengers?.at(0)?.rideNumber || 1;
		return value || 1;
	}, []);

	const sortOrders = useCallback<useOrderSortData.Props["sortOrders"]>(
		(
			value,
			sort = { column: ColumnId.ExecutorArrivalTime, type: "asc" },
		) => {
			if (!value?.length) return [];

			if (sort.type === "asc") {
				if (sort.column === ColumnId.OrderDate) {
					const items = [...value].sort((prev, next) => {
						const prevName = getOrderDate(prev);
						const nextName = getOrderDate(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.OrderTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getOrderDate(prev);
						const nextName = getOrderDate(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorArrivalDate) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorArrivalTime(prev);
						const nextName = getExecutorArrivalTime(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorArrivalTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorArrivalTime(prev);
						const nextName = getExecutorArrivalTime(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.TimeToDelivery) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTimeToDelivery(prev);
						const nextName = getTimeToDelivery(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorCallSign) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorCallSign(prev);
						const nextName = getExecutorCallSign(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.PickupSettlement) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPickupSettlement(prev);
						const nextName = getPickupSettlement(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.PickupAddress) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPickupAddress(prev);
						const nextName = getPickupAddress(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.PickupSector) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPickupSector(prev);
						const nextName = getPickupSector(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Phone) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPhone(prev);
						const nextName = getPhone(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.OrderPrice) {
					const items = [...value].sort((prev, next) => {
						const prevName = getOrderPrice(prev);
						const nextName = getOrderPrice(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CustomerCallStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCustomerCallStatus(prev);
						const nextName = getCustomerCallStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorCallStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorCallStatus(prev);
						const nextName = getExecutorCallStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.OrderNumber) {
					const items = [...value].sort((prev, next) => {
						const prevName = getOrderNumber(prev);
						const nextName = getOrderNumber(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutingStageWithClientsTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutingStageWithClientsTime(prev);
						const nextName = getExecutingStageWithClientsTime(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CarClass) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCarClass(prev);
						const nextName = getCarClass(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.DestinationSettlement) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDestinationSettlement(prev);
						const nextName = getDestinationSettlement(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.DestinationAddress) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDestinationAddress(prev);
						const nextName = getDestinationAddress(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.DestinationSector) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDestinationSector(prev);
						const nextName = getDestinationSector(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.PaymentType) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPaymentType(prev);
						const nextName = getPaymentType(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.DispatcherCallSign) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDispatcherCallSign(prev);
						const nextName = getDispatcherCallSign(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Status) {
					const items = [...value].sort((prev, next) => {
						const prevName = getStatus(prev);
						const nextName = getStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Source) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSource(prev);
						const nextName = getSource(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutingStage) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutingStage(prev);
						const nextName = getExecutingStage(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CloseReason) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCloseReason(prev);
						const nextName = getCloseReason(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.RideNumber) {
					const items = [...value].sort((prev, next) => {
						const prevName = getRideNumber(prev);
						const nextName = getRideNumber(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.TotalRideCount) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTotalRideCount(prev);
						const nextName = getTotalRideCount(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CreateOrderDate) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCreateOrderDate(prev);
						const nextName = getCreateOrderDate(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CreateOrderTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCreateOrderTime(prev);
						const nextName = getCreateOrderTime(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CustomerFullName) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCustomerFullName(prev);
						const nextName = getCustomerFullName(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Counterparty) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCounterparty(prev);
						const nextName = getCounterparty(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CounterpartyCheck) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCounterpartyCheck(prev);
						const nextName = getCounterpartyCheck(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CounterpartyCheckCard) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCounterpartyCheckCard(prev);
						const nextName = getCounterpartyCheckCard(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorLate) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorLate(prev);
						const nextName = getExecutorLate(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutingStageAcceptedTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutingStageAcceptedTime(prev);
						const nextName = getExecutingStageAcceptedTime(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutingStageArrivedTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutingStageArrivedTime(prev);
						const nextName = getExecutingStageArrivedTime(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (
					sort.column === ColumnId.ExecutingStageWaitingCustomerTime
				) {
					const items = [...value].sort((prev, next) => {
						const prevName =
							getExecutingStageWaitingCustomerTime(prev);
						const nextName =
							getExecutingStageWaitingCustomerTime(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSLateStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSLateStatus(prev);
						const nextName = getSMSLateStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSWillBeAtStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSWillBeAtStatus(prev);
						const nextName = getSMSWillBeAtStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSCarNotFoundStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSCarNotFoundStatus(prev);
						const nextName = getSMSCarNotFoundStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSAwaitingPassengersStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSAwaitingPassengersStatus(prev);
						const nextName = getSMSAwaitingPassengersStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSWithPassengersStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSWithPassengersStatus(prev);
						const nextName = getSMSWithPassengersStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSOrderCompletedStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSOrderCompletedStatus(prev);
						const nextName = getSMSOrderCompletedStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSExecutorSwitchedStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSExecutorSwitchedStatus(prev);
						const nextName = getSMSExecutorSwitchedStatus(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Company) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCompany(prev);
						const nextName = getCompany(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.TaxiService) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTaxiService(prev);
						const nextName = getTaxiService(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.IdleTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getIdleTime(prev);
						const nextName = getIdleTime(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}
				if (sort.column === ColumnId.Tariff) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTariff(prev);
						const nextName = getTariff(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Services) {
					const items = [...value].sort((prev, next) => {
						const prevName = getServices(prev);
						const nextName = getServices(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Parameters) {
					const items = [...value].sort((prev, next) => {
						const prevName = getParameters(prev);
						const nextName = getParameters(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Discount) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDiscount(prev);
						const nextName = getDiscount(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.TransportationType) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTransportationType(prev);
						const nextName = getTransportationType(next);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}
			}

			// desc
			if (sort.type === "desc") {
				if (sort.column === ColumnId.OrderDate) {
					const items = [...value].sort((prev, next) => {
						const prevName = getOrderDate(next);
						const nextName = getOrderDate(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.OrderTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getOrderDate(next);
						const nextName = getOrderDate(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorArrivalDate) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorArrivalTime(next);
						const nextName = getExecutorArrivalTime(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorArrivalTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorArrivalTime(next);
						const nextName = getExecutorArrivalTime(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.TimeToDelivery) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTimeToDelivery(next);
						const nextName = getTimeToDelivery(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorCallSign) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorCallSign(next);
						const nextName = getExecutorCallSign(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.PickupSettlement) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPickupSettlement(next);
						const nextName = getPickupSettlement(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.PickupAddress) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPickupAddress(next);
						const nextName = getPickupAddress(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.PickupSector) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPickupSector(next);
						const nextName = getPickupSector(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Phone) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPhone(next);
						const nextName = getPhone(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.OrderPrice) {
					const items = [...value].sort((prev, next) => {
						const prevName = getOrderPrice(next);
						const nextName = getOrderPrice(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CustomerCallStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCustomerCallStatus(next);
						const nextName = getCustomerCallStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorCallStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorCallStatus(next);
						const nextName = getExecutorCallStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.OrderNumber) {
					const items = [...value].sort((prev, next) => {
						const prevName = getOrderNumber(next);
						const nextName = getOrderNumber(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutingStageWithClientsTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutingStageWithClientsTime(next);
						const nextName = getExecutingStageWithClientsTime(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CarClass) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCarClass(next);
						const nextName = getCarClass(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.DestinationSettlement) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDestinationSettlement(next);
						const nextName = getDestinationSettlement(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.DestinationAddress) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDestinationAddress(next);
						const nextName = getDestinationAddress(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.DestinationSector) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDestinationSector(next);
						const nextName = getDestinationSector(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.PaymentType) {
					const items = [...value].sort((prev, next) => {
						const prevName = getPaymentType(next);
						const nextName = getPaymentType(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.DispatcherCallSign) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDispatcherCallSign(next);
						const nextName = getDispatcherCallSign(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Status) {
					const items = [...value].sort((prev, next) => {
						const prevName = getStatus(next);
						const nextName = getStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Source) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSource(next);
						const nextName = getSource(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutingStage) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutingStage(next);
						const nextName = getExecutingStage(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CloseReason) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCloseReason(next);
						const nextName = getCloseReason(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.TotalRideCount) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTotalRideCount(next);
						const nextName = getTotalRideCount(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CreateOrderDate) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCreateOrderDate(next);
						const nextName = getCreateOrderDate(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CreateOrderTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCreateOrderTime(next);
						const nextName = getCreateOrderTime(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CustomerFullName) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCustomerFullName(next);
						const nextName = getCustomerFullName(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Counterparty) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCounterparty(next);
						const nextName = getCounterparty(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CounterpartyCheck) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCounterpartyCheck(next);
						const nextName = getCounterpartyCheck(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.CounterpartyCheckCard) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCounterpartyCheckCard(next);
						const nextName = getCounterpartyCheckCard(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutorLate) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutorLate(next);
						const nextName = getExecutorLate(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutingStageAcceptedTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutingStageAcceptedTime(next);
						const nextName = getExecutingStageAcceptedTime(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.ExecutingStageArrivedTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getExecutingStageArrivedTime(next);
						const nextName = getExecutingStageArrivedTime(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (
					sort.column === ColumnId.ExecutingStageWaitingCustomerTime
				) {
					const items = [...value].sort((prev, next) => {
						const prevName =
							getExecutingStageWaitingCustomerTime(next);
						const nextName =
							getExecutingStageWaitingCustomerTime(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSLateStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSLateStatus(next);
						const nextName = getSMSLateStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSWillBeAtStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSWillBeAtStatus(next);
						const nextName = getSMSWillBeAtStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSCarNotFoundStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSCarNotFoundStatus(next);
						const nextName = getSMSCarNotFoundStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSAwaitingPassengersStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSAwaitingPassengersStatus(next);
						const nextName = getSMSAwaitingPassengersStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSWithPassengersStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSWithPassengersStatus(next);
						const nextName = getSMSWithPassengersStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSOrderCompletedStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSOrderCompletedStatus(next);
						const nextName = getSMSOrderCompletedStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.SMSExecutorSwitchedStatus) {
					const items = [...value].sort((prev, next) => {
						const prevName = getSMSExecutorSwitchedStatus(next);
						const nextName = getSMSExecutorSwitchedStatus(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Company) {
					const items = [...value].sort((prev, next) => {
						const prevName = getCompany(next);
						const nextName = getCompany(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.TaxiService) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTaxiService(next);
						const nextName = getTaxiService(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}
				if (sort.column === ColumnId.IdleTime) {
					const items = [...value].sort((prev, next) => {
						const prevName = getIdleTime(next);
						const nextName = getIdleTime(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Tariff) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTariff(next);
						const nextName = getTariff(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Services) {
					const items = [...value].sort((prev, next) => {
						const prevName = getServices(next);
						const nextName = getServices(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Parameters) {
					const items = [...value].sort((prev, next) => {
						const prevName = getParameters(next);
						const nextName = getParameters(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.Discount) {
					const items = [...value].sort((prev, next) => {
						const prevName = getDiscount(next);
						const nextName = getDiscount(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.TransportationType) {
					const items = [...value].sort((prev, next) => {
						const prevName = getTransportationType(next);
						const nextName = getTransportationType(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}

				if (sort.column === ColumnId.RideNumber) {
					const items = [...value].sort((prev, next) => {
						const prevName = getRideNumber(next);
						const nextName = getRideNumber(prev);
						return sortByNumberOrString(prevName, nextName);
					});

					return items;
				}
			}

			return value;
		},
		[
			getRideNumber,
			getOrderDate,
			getExecutorArrivalTime,
			getTimeToDelivery,
			getExecutorCallSign,
			getPickupSettlement,
			getPickupAddress,
			getPickupSector,
			getPhone,
			getOrderPrice,
			getCustomerCallStatus,
			getExecutorCallStatus,
			getOrderNumber,
			getExecutingStageWithClientsTime,
			getCarClass,
			getDestinationSettlement,
			getDestinationAddress,
			getDestinationSector,
			getPaymentType,
			getDispatcherCallSign,
			getStatus,
			getSource,
			getExecutingStage,
			getCloseReason,
			getTotalRideCount,
			getCreateOrderDate,
			getCreateOrderTime,
			getCustomerFullName,
			getCounterparty,
			getCounterpartyCheck,
			getCounterpartyCheckCard,
			getExecutorLate,
			getExecutingStageAcceptedTime,
			getExecutingStageArrivedTime,
			getExecutingStageWaitingCustomerTime,
			getSMSLateStatus,
			getSMSWillBeAtStatus,
			getSMSCarNotFoundStatus,
			getSMSAwaitingPassengersStatus,
			getSMSWithPassengersStatus,
			getSMSOrderCompletedStatus,
			getSMSExecutorSwitchedStatus,
			getCompany,
			getTaxiService,
			getIdleTime,
			getTariff,
			getServices,
			getParameters,
			getDiscount,
			getTransportationType,
		],
	);

	return useMemo(
		() => ({
			sortOrders,
		}),
		[sortOrders],
	);
};
declare namespace useOrderSortData {
	interface Props {
		sortOrders: (
			value: Order.Model[],
			sort: {
				column?: ColumnId | string;
				type: SortType | undefined;
			},
		) => Order.Model[];
	}
}

export { ColumnId };
export default useOrderSortData;
