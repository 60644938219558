import { isNumber } from "lodash";

import {
	DateFns,
	ConstantFormat,
	ValueFormat,
} from "../../../../../../../utils/DateFns";

interface FormatDateParams {
	date: string | number | Date;
	isTitleFormat?: boolean;
	isTimeFormat?: boolean;
	isDayFormat?: boolean;
}

const formatDate = ({
	date,
	isTitleFormat = false,
	isTimeFormat = false,
	isDayFormat = false,
}: FormatDateParams): string => {
	const dateFns = new DateFns();

	let format: ValueFormat;
	switch (true) {
		case isTitleFormat:
			format = ConstantFormat.MDY_UK;
			break;
		case isTimeFormat:
			format = ConstantFormat.HM_UK;
			break;
		case isDayFormat:
			format = ConstantFormat.MDY_UK;
			break;
		default:
			format = ConstantFormat.MDYHM_UK;
	}

	return dateFns.formatTime(date, format);
};

const formatDistance = (distance: number): string => {
	const value = isNumber(distance) ? distance : 0;

	const retval = (value / 1000).toFixed(3);
	return retval;
};

const formatMoney = (money: number): string => {
	const value = isNumber(money) ? money : 0;

	const retval = value.toFixed(2);
	return retval;
};

const formatTimeToMinutes = (milliseconds: number): number => {
	if (!isNumber(milliseconds) || milliseconds < 0) {
		return 0;
	}

	const seconds = Math.abs(milliseconds) / 1000;
	const minutes = Math.floor(seconds / 60);
	return minutes;
};

export { formatDate, formatDistance, formatMoney, formatTimeToMinutes };
