/* eslint-disable no-param-reassign */
import * as ModelEvent from "@node-elion/syncron";
import { compact } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { Language } from "../../assets/languages/langs";
import Subscription from "../../types/Subscription";
import { PaymentAccount } from "../../types/PaymentAccount";
import Gender from "../../types/Gender";
import Nullable from "../../types/Nullable";
import Base from "../Base";
import Person from "../Person";
import File from "../File";
import ClientGroup from "../ClientGroup";
import CustomerRate from "../CustomerRate";
import { DiscountPlan } from "../../redux/services/Order/getPhoneInfo";

import { destroyOne } from "./utils";

class Client extends Base {
	static defaultSharedOptions: Client.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): Client.Model {
		const paymentAccounts = data.paymentAccounts?.filter?.((item) => {
			if (item?.type === "main" || item?.type === "bonus") {
				return true;
			}
			return false;
		});

		const plan = (value, type: "additionalPlan" | "mainPlan") => {
			try {
				const exist = value?.[type];
				if (!exist) return undefined;
				return {
					active: exist.active,
					name: exist.name,
					discount:
						exist.configuration?.types?.[exist.configuration?.type]
							?.discount,
					maxDiscount: exist.configuration?.general?.maxDiscount,
					type: exist.configuration?.type,
					types: exist.configuration?.types,
				};
			} catch {
				return undefined;
			}
		};

		return {
			id: data.id,

			additionalPlan: plan(data, "additionalPlan"),
			mainPlan: plan(data, "mainPlan"),

			mainRate: data.mainPlan
				? CustomerRate.fromResponse(data.mainPlan)
				: undefined,
			additionalRate: data.additionalPlan
				? CustomerRate.fromResponse(data.additionalPlan)
				: undefined,

			rideCount: data.rideCount,
			counters: data.counters,

			companyId: data.company?.id,
			carClassId: data.carClass?.id,
			defaultTaxiServiceId: data.defaultTaxiService?.id,
			latestTaxiServiceId: data.latestTaxiService?.id,

			person: data.person
				? {
						id: data.person.id,

						firstName: data.person.name,
						lastName: data.person.surname,
						fatherName: data.person.fatherName,
						birthday: data.person.birthday,

						emails: data.person.emails,
						phones: data.person.phones,

						createdAt: data.person.createdAt,
						updatedAt: data.person.updatedAt,
						deletedAt: data.person.deletedAt,
				  }
				: data.person,

			login: data.login,
			hasPassword: data.isPassword,
			gender: data.gender,
			status: data.status,
			notes: data.notes || "",
			orderNotes: data.orderNotes || "",
			executorNotes: data.executorNotes || "",

			smsNotifications: data.smsNotifications,

			additionalFields: {
				defaultAddress: data.additionalFields?.defaultAddress,

				...data.additionalFields,
			},
			paymentAccounts: compact(paymentAccounts) ?? [],

			customerToForbiddenExecutors: data.customerToForbiddenExecutors,
			customerToUndesirableExecutors: data.customerToUndesirableExecutors,
			customerToPriorityExecutors: data.customerToPriorityExecutors,

			personalFiles: data.personalFiles?.map(File.fromResponse),
			otherFiles: data.otherFiles?.map(File.fromResponse),
			downloadedFiles: data.downloadedFiles?.map(File.fromResponse),

			customerGroup: data.customerGroup,
			customerGroupId: data.customerGroup?.id,

			passengers: data.passengers,
			customerToPaymentTypes: data.customerToPaymentTypes,
			customerToCustomerCompanies: data.customerToCustomerCompanies,

			isApp: data.isApp,
			isAllowedApp: data.isAllowedApp,
			isAllowedCabinet: data.isAllowedCabinet,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static toRequest(
		model: (Client.Model.New & { id?: undefined }) | Client.Model.Modified,
	): any {
		const mainDiscountPlanId =
			model.mainRateId === 0 ? null : model.mainRateId;
		const additionalDiscountPlanId =
			model.additionalRateId === 0 ? null : model.additionalRateId;

		const params: Client.Model.CreateParams = {
			customerGroupId: model.customerGroupId,

			companyId: model.id == null ? model.companyId : undefined,
			carClassId: model.carClassId,
			defaultTaxiServiceId: model.defaultTaxiServiceId,

			phones:
				model.person?.phones?.filter(Boolean).map((number, i) => ({
					number,
					group: i === 0 ? 0 : 1,
				})) || [],
			emails:
				model.person?.emails?.filter(Boolean).map((value) => ({
					value,
					group: 0,
				})) || [],

			name: model.person?.firstName || "",
			surname: model.person?.lastName || "",
			fatherName: model.person?.fatherName || "",
			birthday:
				model.person?.birthday == null
					? undefined
					: new Date(model.person?.birthday),
			city: model.person?.city || undefined,
			country: model.person?.country || undefined,
			address: model.person?.address || undefined,

			login: model.login,
			password: model?.password,
			gender: model.gender,
			status: model.status,

			notes: model.notes,
			orderNotes: model?.orderNotes || "",
			executorNotes: model?.executorNotes || "",

			// avatarFileId?: model.		,
			// otherFileIds?: model.		,

			forbiddenExecutors: model.customerToForbiddenExecutors,
			undesirableExecutors: model.customerToUndesirableExecutors,
			priorityExecutors: model.customerToPriorityExecutors,

			smsNotifications: model.smsNotifications,

			isAllowedApp: model.isAllowedApp,
			isAllowedCabinet: model.isAllowedCabinet,

			additionalFields: model.additionalFields,

			personalFileIds: model.personalFileIds,
			otherFileIds: model.otherFileIds,
			// downloadedFileIds: model.downloadedFileIds,
		};

		if (!("id" in model)) {
			if (model.paymentTransactions.main.length)
				params.paymentTransactions = {
					main: model.paymentTransactions.main,
				};
			if (model.paymentTransactions.bonus.length)
				params.paymentTransactions = {
					...params.paymentTransactions,
					bonus: model.paymentTransactions.bonus,
				};
		}

		console.log("Client toRequest", {
			model,
			params,
			mainDiscountPlanId,
			additionalDiscountPlanId,
		});

		return {
			...params,
			mainDiscountPlanId,
			additionalDiscountPlanId,
		};
	}

	static async getHistory(id: number) {
		const prpc = getPRPC();

		if (!prpc) return [];

		const result = await createRPCQuery(() =>
			prpc.theirsModel.customer.getHistory(id),
		);

		return result as unknown as Client.History;
	}

	static async store(object: Client.Model.New) {
		const prpc = getPRPC();
		if (!prpc) return null;

		const result = await createRPCQuery(() =>
			prpc.theirsModel.customer.create(Client.toRequest(object)),
		);

		return this.fromResponse(result);
	}

	static async update(object: Client.Model.Modified) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.customer.update(
				object.id,
				Client.toRequest(object),
			),
		);
	}

	static async updateStatus(object: {
		id: Client.Model["id"];
		status: Client.Model["status"];
	}) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.customer.update(object.id, object),
		);
	}

	static async rating(object: {
		id: Client.Model["id"];
	}): Promise<number | null> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.feedback.customer.rating(object.id),
			);
			return res;
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	static async destroy(id: number[] | number) {
		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);
	}

	static Global = {
		async index(options: Client.SubscribeOptions) {
			const prpc = getPRPC();
			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.customer.getAll({
					limit: options.limit,
					offset: options.offset,
					order: options.order,
					query: options.query,
				}),
			);

			const cache = (result.items as any[]).map(Client.fromResponse);

			return {
				cache,
				offset: 0,
				limit: cache.length,
				total: cache.length,
				deprecated: false,
			};
		},
	};

	public static async subscribe(
		options: Client.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Client.Model>,
	): Promise<Subscription<Client.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[Client] subscribe", state);
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.customer.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						console.error(error);
					},
				}),
			{ name: "Client.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Client.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Client.SubscribeOptions>;
	}

	private static optionsToRequest(options: Client.SubscribeOptions) {
		return {
			query: options.query,
			offset: options.offset,
			limit: options.limit,
			order: options.order,

			lang: options.language,
		};
	}
}

namespace Client {
	export enum Status {
		ACTIVE = "active",
		NO_ACTIVE = "not_active",
		PHONE = "phone",
		BLOCKED = "blocked",
		WARNING = "warning",
	}

	export namespace Status {
		export type Closed = "blocked" | "dismissed";
	}

	export type History = History.Item[];

	export namespace History {
		export interface Item {
			id: number;

			user: null;

			action: "create" | "update" | "delete";
			comment: string;
			changes: Entry[];

			timestamp: number;
			version: number;
		}

		export namespace Entry {
			export interface AnyChange<
				Field extends string,
				Value,
				Type extends "simple" | "array",
			> {
				previous?: Value;
				actual?: Value;
				field: Field;
				type: Type extends "simple"
					? "update"
					:
							| "array_item_add"
							| "array_item_remove"
							| "array_item_update";
			}

			// Personal data
			export type NameChange = AnyChange<"name", string, "simple">;
			export type SurnameChange = AnyChange<"surname", string, "simple">;
			export type FatherNameChange = AnyChange<
				"fatherName",
				string,
				"simple"
			>;
			export type BirthdayChange = AnyChange<"birthday", Date, "simple">;
			export type GenderChange = AnyChange<"gender", Gender, "simple">;

			export type EmailsChange = AnyChange<
				"emails",
				Person.Model.Email[],
				"array"
			>;
			export type PhonesChange = AnyChange<
				"phones",
				Person.Model.Phone[],
				"array"
			>;

			// Additional data
			// type ClientGroupChange = AnyChange<"clientGroup", Record<Language,string>, "simple">;
			// type MainLoyaltyProgramChange = AnyChange<"mainLoyaltyProgram", Record<Language,string>, "simple">;
			// type AdditionalLoyaltyProgramChange = AnyChange<"additionalLoyaltyProgram", Record<Language,string>, "simple">;
			// type BonusCardChange = AnyChange<"bonusCard", Record<Language,string>, "simple">;
			// type TariffChange = AnyChange<"tariff", Record<Language,string>, "simple">;
			export type CarClassChange = AnyChange<
				"carClass",
				Record<Language, string>,
				"simple"
			>;

			// Other
			export type StatusChange = AnyChange<
				"status",
				Client.Status,
				"simple"
			>;
			// type CompanyChange = AnyChange<"company", any, "simple">;
			// type TaxiServiceChange = AnyChange<"taxiService", any, "simple">;

			// Options
			export type SMSNotificationsChange = AnyChange<
				"smsNotifications",
				boolean,
				"simple"
			>;
		}

		export type Entry =
			| Entry.NameChange
			| Entry.SurnameChange
			| Entry.FatherNameChange
			| Entry.BirthdayChange
			| Entry.GenderChange
			| Entry.EmailsChange
			| Entry.PhonesChange
			| Entry.CarClassChange
			| Entry.StatusChange
			| Entry.SMSNotificationsChange;
	}

	export enum Group {
		main,
		additional,
	}

	export interface AdditionalFields {
		defaultAddress?: string;

		additionalRideCounts?: {
			closedRideCount: number;
			successRideCount: number;
			canceledRideCount: number;
		};
	}

	export interface Company {
		id: number;
		name: Record<Language, string>;
		login: string;

		createdAt: Date;
		updatedAt: Date;
		deletedAt: Date | null;
	}

	export interface ExecutorRelation {
		id: number;
		notes: string;
		status: string;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	export interface ForbiddenExecutors extends ExecutorRelation {
		status: "forbidden";
		forbiddenExecutors: unknown;
	}
	export interface UndesirableExecutors extends ExecutorRelation {
		status: "undesirable";
		undesirableExecutors: unknown;
	}
	export interface PriorityExecutors extends ExecutorRelation {
		status: "priority";
		priorityExecutors: unknown;
	}

	export interface CarClass {
		id: number;
		name: Record<Language, string>;
		active: boolean;
		default: boolean;
		position: string; // '2023-06-12T14:01:59.091Z',

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	export interface Model {
		id: number;

		additionalPlan?: DiscountPlan | null;
		mainPlan?: DiscountPlan | null;

		mainRate?: CustomerRate.Model;
		additionalRate?: CustomerRate.Model;
		rideCount: number;
		counters: {
			total: number;
			success: number;
			cancelled: number;
			failed: number;
		};

		customerGroup: ClientGroup.Model;
		customerGroupId: number | undefined;

		person: Person.Model;
		gender: Nullable<Gender>;

		paymentAccounts: PaymentAccount[];

		carClassId?: number;

		status: {
			level: Status;
			description: string;
		};
		companyId: number;
		defaultTaxiServiceId?: number;
		latestTaxiServiceId?: number;

		notes: string;
		orderNotes?: string;
		executorNotes?: string;

		smsNotifications: boolean;
		login?: string;
		hasPassword: boolean;
		password?: string;

		additionalFields: AdditionalFields;

		customerToForbiddenExecutors: ForbiddenExecutors[];
		customerToUndesirableExecutors: UndesirableExecutors[];
		customerToPriorityExecutors: PriorityExecutors[];

		personalFiles: File.Model[];
		otherFiles: File.Model[];
		downloadedFiles: File.Model[];

		passengers: any[];
		customerToPaymentTypes: any[];
		customerToCustomerCompanies: any[];

		isApp: boolean;
		isAllowedApp: boolean;
		isAllowedCabinet: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}
	export interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Client.Model> {
		language?: Language;
		statuses?: Client.Status[];
	}

	export interface SharedOptions {
		deprecate?: boolean;
	}

	export namespace Model {
		export interface CreateParams {
			companyId: number | undefined; // required for creation
			mainDiscountPlanId?: number;
			additionalDiscountPlanId?: number;

			name?: string;
			surname?: string;
			fatherName?: string;
			birthday?: Date;
			city?: string;
			country?: string;
			address?: string;

			phones: Pick<Person.Model.Phone, "number" | "group">[];
			emails: Pick<Person.Model.Email, "value" | "group">[];

			login?: string;
			password?: string;
			gender?: Nullable<string>;
			status?: {
				level: Status;
				description: string;
			};

			notes?: string;
			orderNotes?: string;
			executorNotes?: string;

			carClassId?: number;
			defaultTaxiServiceId?: number;

			// avatarFileId?: number;
			// otherFileIds?: number[];

			forbiddenExecutors?: ForbiddenExecutors[];
			undesirableExecutors?: UndesirableExecutors[];
			priorityExecutors?: PriorityExecutors[];

			smsNotifications?: boolean;

			customerGroupId?: number;

			isAllowedApp?: boolean;
			isAllowedCabinet?: boolean;

			additionalFields?: AdditionalFields;

			personalFileIds?: number[];
			otherFileIds?: number[];

			paymentTransactions?: Partial<PaymentTransactions>;
		}

		export type UpdateParams = Partial<CreateParams>;

		export type NonEditablePropertyNames =
			| "id"
			| "createdAt"
			| "updatedAt"
			| "deletedAt";

		export type ModifiedPropertyNames = "person";

		export type PartialNewPropertyNames =
			| "gender"
			| "status"
			| "notes"
			| "company"
			| "carClass"
			| "avatarFile"
			| "otherFiles"
			| "customerToForbiddenExecutors"
			| "customerToUndesirableExecutors"
			| "customerToPriorityExecutors"
			| "isBlockedApp"
			| "isBlockedCabinet"
			| "smsNotifications"
			| "additionalFields";

		export type RequiredNewProperties = Required<Pick<Model, "companyId">>;
		export type PartialNewProperties = Partial<
			Pick<
				Model,
				| "companyId"
				| "carClassId"
				| "defaultTaxiServiceId"
				| "customerGroupId"
				//
				// | "avatarFile"
				// | "otherFiles"
				//
				| "customerToForbiddenExecutors"
				| "customerToUndesirableExecutors"
				| "customerToPriorityExecutors"
				//
				| "status"
				| "gender"
				| "notes"
				| "orderNotes"
				| "executorNotes"
				| "login"

				//
				| "smsNotifications"
				//
				| "isAllowedApp"
				| "isAllowedCabinet"
				//
				| "additionalFields"
			>
		>;

		export type New = RequiredNewProperties &
			PartialNewProperties & {
				mainRateId?: number;
				additionalRateId?: number;

				password?: string;
				person: Person.Model.New;

				personalFileIds: number[];
				otherFileIds: number[];

				paymentTransactions: PaymentTransactions;
			};

		type PaymentTransactions = {
			main: { amount: number; description?: string }[];
			bonus: { amount: number; description?: string }[];
		};

		export type Modified = Pick<Model, "id"> &
			Partial<
				Omit<
					Model,
					NonEditablePropertyNames | ModifiedPropertyNames
				> & {
					mainRateId?: number;
					additionalRateId?: number;

					password?: string;
					person: Person.Model.Modified;

					personalFileIds: number[];
					otherFileIds: number[];
				}
			>;
	}
}

export default Client;
