import { useCallback, useMemo } from "react";
import { useInternal } from "uikit";

import { Archive } from "../../../../../../../redux/reducers/Archives/interface";
import calcOrderCost from "../../../../../../../redux/services/Order/calcOrderCost";
import { useTypedDispatch } from "../../../../../../../redux/store";
import { Language } from "../../../../../../../assets/languages/langs";
import {
	IOrderPoint,
	RoundingMethod,
	OrderCompanyBillMetadata,
} from "../../../../../../../redux/constants/OrdersPage/order";
import {
	DiscountPlan,
	DiscountType,
} from "../../../../../../../redux/services/Order/getPhoneInfo";

export type OrderCostRequest = {
	paymentType?: Archive.Orders.Order["paymentType"];
	rollback?: boolean;
	source?: Archive.ValueOrderSource;
	isPreliminary: boolean;
	orderDate: string | Date | number;
	rateId: number;
	id?: number;
	taxiServiceId: number;
	counterpartyId?: number;
	checkCardId?: number;
	carClassIds: number[];
	serviceIds: number[];
	agentId?: number;
	agentRewardId?: number;
	paymentChangeType?: Archive.ValueOrderPaymentChangeType;

	/** this orderFormTab.form as `JSON.stringify` */
	form?: string;
	additionalFields: {
		rateSettings: Archive.Orders.Order["additionalFields"]["rateSettings"];
		passengersCount: Archive.Orders.Order["additionalFields"]["passengersCount"];
		suburbanDistanceOverride: Archive.Orders.Order["additionalFields"]["suburbanDistanceOverride"];
		waitingTimeMilliseconds: Archive.Orders.Order["additionalFields"]["waitingTimeMilliseconds"];
		idleTimeMilliseconds: Archive.Orders.Order["additionalFields"]["idleTimeMilliseconds"];
		additionalCost: Archive.Orders.Order["additionalFields"]["additionalCost"];
		discount?: Archive.Orders.Discount;
		taximeter: Archive.Orders.Order["additionalFields"]["taximeter"];
	};
	passengers: {
		points: {
			coordinates: IOrderPoint["coordinates"];
			feature: IOrderPoint;
		}[];
		location: IOrderPoint["coordinates"];
		customerId: number;
	}[];
};

export interface OrderCostCurrency {
	id: number;
	settings: { code: string; name: string; symbol: string };
	name: Record<Language, string>;
}

export interface CustomerCostDiscountPlan {
	id: number;
	name: Record<Language, string>;
	active: boolean;
	discountType: DiscountType;
	type: "main" | "additional";
	conciseSettings: Record<string, any>;
	v: number;
}

export interface OrderCostStage {
	value: number;
	stage: Archive.ValueOrderCostCalculationStage;
	/**
	 * Order cost discounts
	 */

	details: {
		/**
		 * ```ts
		 * "{{settlementDistance}} * ({{settlementCostPerKm}} + {{settlementCostMargin}})"
		 * ```
		 * */
		expression: string;
		variables: Record<string, any> & {
			settlementCostPerKm: number;
			settlementCostMargin: number;
			settlementDistance: number;
		};
	};
}

export interface OrderCostResponse {
	/**
	 * The cost of the order without rounding
	 */
	raw: number;

	/**
	 * The final order cost with rounding
	 */
	value: number;

	/**
	 * The order cost with simple rounding
	 */
	rounded: string;

	/**
	 * Stages of the cost calculation
	 */
	stages: OrderCostStage[];

	discount?: Archive.Orders.DiscountCost;

	/**
	 * Distances of the order route
	 */
	distances: {
		/**
		 * The distance of the order route (in meters)
		 */
		total: number;

		/**
		 * The settlement distance of the order route (in meters)
		 */
		settlement: number;

		/**
		 * The suburban distance of the order route (in meters)
		 */
		suburban: number;
	};

	/**
	 * Order cost margin
	 */
	margin: {
		coefficient: number;
		fixed: number;
	};
	/**
	 * The currency of the order cost
	 */
	currency: OrderCostCurrency;
	settings: Record<string, any> & {
		autoRates: Record<string, any>[];
		customerDiscountPlans: CustomerCostDiscountPlan[];
		rate: {
			id: number;
			name: Record<Language, string>;
			v: number;
		};
		rounding: {
			method: RoundingMethod;
			multiple: {
				active: boolean;
				value: number;
			};
			precision: number;
		};
	};
	companyBillMetadata: OrderCompanyBillMetadata | null;
}

interface GetDiscountPlanRes {
	additionalPlan?: DiscountPlan;
	mainPlan?: DiscountPlan;
}

export interface UseCalcOrderCost {
	response: OrderCostResponse | null;
	error: unknown | null;
	get: (params: OrderCostRequest) => Promise<OrderCostResponse | null>;
	getDiscountPlan: (
		data?: CustomerCostDiscountPlan,
		counters?: { total?: number },
	) => DiscountPlan | undefined;
	getDiscountPlans: (
		data?: CustomerCostDiscountPlan[],
		counters?: { total?: number },
	) => GetDiscountPlanRes;
	reset: () => void;
}

export type UseCalcOrderCostProps = () => UseCalcOrderCost;

/**
 *  `useCalcOrderCost`
 */
export const useCalcOrderCost: UseCalcOrderCostProps = () => {
	const dispatch = useTypedDispatch();
	const [response, setResponse] = useInternal<OrderCostResponse | null>(null);
	const [error, setError] = useInternal<unknown | null>(null);

	const get = useCallback<UseCalcOrderCost["get"]>(
		async (params) => {
			try {
				setError(null);
				const res: any = await dispatch(calcOrderCost(params));
				setResponse(res);
				return res;
			} catch (error) {
				setError(error);
				return null;
			}
		},
		[dispatch, setError, setResponse],
	);

	const getDiscountPlan = useCallback<UseCalcOrderCost["getDiscountPlan"]>(
		(data) => {
			try {
				if (!data) return undefined;

				const payload = {
					active: data?.active,
					name: data?.name,
					type: data?.discountType,
					maxDiscount: data?.conciseSettings?.maxDiscount,
					discount: data?.conciseSettings?.discount,
					appliesToMinimal: data?.conciseSettings?.appliesToMinimal,
				};

				return payload;
			} catch {
				return undefined;
			}
		},
		[],
	);

	const getDiscountPlans = useCallback<UseCalcOrderCost["getDiscountPlans"]>(
		(data) => {
			try {
				if (!data) {
					return {
						additionalPlan: undefined,
						mainPlan: undefined,
					};
				}
				const main = data.find((item) => item.type === "main");
				const additional = data.find(
					(item) => item.type === "additional",
				);

				return {
					additionalPlan: getDiscountPlan(additional),
					mainPlan: getDiscountPlan(main),
				};
			} catch {
				return {
					additionalPlan: undefined,
					mainPlan: undefined,
				};
			}
		},
		[getDiscountPlan],
	);

	const reset = useCallback(() => {
		setResponse(null);
		setError(null);
	}, [setError, setResponse]);

	return useMemo<UseCalcOrderCost>(
		() => ({
			getDiscountPlan,
			getDiscountPlans,
			response,
			error,
			reset,
			get,
		}),
		[getDiscountPlan, getDiscountPlans, response, error, reset, get],
	);
};
