/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";
import { isUndefined } from "lodash";

import Client from "../../../../../../services/Client";
import CarClass from "../../../../../../services/CarClass";
import Company from "../../../../../../services/Company";
import TaxiService from "../../../../../../services/TaxiService";
import ClientGroup from "../../../../../../services/ClientGroup";
import useOldModelSubscribe from "../../../../../../hooks/useModelSubscribe";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import useDateTimeLocalizer from "../../../../../../hooks/useDateLocalizer";
import { useSort } from "../../../../../../hooks/useTableSettings";
import makeLookupTable from "../../../../../../utils/makeLookupTable";
import formatNumber from "../../../../../../utils/formatNumber";
import DeleteModal from "../../../../../../components/DeleteModal";
import { useTableOptions } from "../../../../../../components/LightTable";

import { sortableColumns } from "./components/Content/components/ModelTable/constants";
import ModelTable from "./components/Content/components/ModelTable";
import Content from "./components/Content";
import Header from "./components/Header";
import EditModal from "./components/EditModal";
import Root from "./components/Root";

const Clients: React.FC = () => {
	const { t } = useTranslation();

	const localizeDate = useDateTimeLocalizer();

	const {
		editor,
		onChange,
		lang: language,
		active,
		limit,
	} = useTableOptions();

	const { models: groups } = useModelSubscribe({}, ClientGroup);
	const companies = useOldModelSubscribe({}, Company)?.cache;
	const taxiServices = useOldModelSubscribe({}, TaxiService)?.cache;

	const companyLookup = useMemo(
		() =>
			companies
				? makeLookupTable(companies, (c) => c.id, true)
				: ({} as Record<number, undefined>),
		[companies],
	);
	const taxiServiceLookup = useMemo(
		() =>
			taxiServices
				? makeLookupTable(taxiServices, (ts) => ts.id, true)
				: ({} as Record<number, undefined>),
		[taxiServices],
	);

	const [headerFilters, setHeaderFilters] = useState<Header.Filters.Value>({
		statuses: [
			Client.Status.ACTIVE,
			Client.Status.BLOCKED,
			Client.Status.PHONE,
			Client.Status.WARNING,
		],
		search: "",

		filter: {}, // not implemented yet
	});

	const { sortMethod } = useSort("customers.clients", "client");

	const order = useMemo(() => {
		if (isUndefined(sortMethod.column) || isUndefined(sortMethod.type)) {
			return undefined;
		}

		const column = sortableColumns[sortMethod.column];
		return column ? { [column]: sortMethod.type } : undefined;
	}, [sortMethod.column, sortMethod.type]);

	const subscribeOptions = useMemo<Client.SubscribeOptions>(
		() => ({
			language,
			order,
			query: headerFilters.search,
			limit,
			statuses: headerFilters.statuses,
		}),
		[headerFilters.search, headerFilters.statuses, language, order, limit],
	);

	const { models: clients } = useModelSubscribe(subscribeOptions, Client);
	const clientLookup = useMemo(
		() => makeLookupTable(clients, (c) => c.id, true),
		[clients],
	);

	// TODO: use redux
	const { models: carClasses } = useModelSubscribe({}, CarClass);
	const carClassLookup = useMemo(
		() => makeLookupTable(carClasses, (c) => c.id, true),
		[carClasses],
	);

	const tableData = useMemo<ModelTable.TableData>(() => {
		if (active === null) return [];
		const payload = [...(clients ?? [])];

		return [...payload]
			.filter((item) => {
				if (active === undefined) return true;
				if (item.status?.level === "active" && active === true) {
					return true;
				}
				if (item.status?.level !== "active" && active === false) {
					return true;
				}
				return false;
			})
			.map((client) => {
				const mainBalance = client?.paymentAccounts.find(
					(a) => a.type === "main",
				);
				const bonusBalance = client?.paymentAccounts.find(
					(a) => a.type === "bonus",
				);

				const company =
					companyLookup[client.companyId]?.value.name?.[language] ||
					"-";

				const defaultTaxiService =
					(client.defaultTaxiServiceId &&
						taxiServiceLookup[client.defaultTaxiServiceId]?.value
							.settlement[language]) ||
					"-";

				const latestTaxiService =
					(client.latestTaxiServiceId &&
						taxiServiceLookup[client.latestTaxiServiceId]?.value
							.settlement[language]) ||
					"-";

				const defaultCarClass =
					(client.carClassId != null &&
						carClassLookup[client.carClassId]?.value.name[
							language
						]) ||
					"-";

				const createdAt = localizeDate(
					new Date(client.createdAt),
					"dateTime",
				);
				const updatedAt = localizeDate(
					new Date(client.updatedAt),
					"dateTime",
				);
				const deletedAt = client.deletedAt
					? localizeDate(new Date(client.deletedAt), "dateTime")
					: "";

				return {
					_isActive: client.status.level === "active",
					id: client.id,

					rideCount: client.rideCount,
					balance: formatNumber(mainBalance?.amount ?? 0),
					bonusBalance: formatNumber(bonusBalance?.amount ?? 0),
					bonusRides: "todo",

					totalOrderCount: client?.counters?.total ?? 0,
					completedOrderCount: client?.counters?.success ?? 0,
					orderEstimationCount: client?.counters?.failed ?? 0,
					canceledOrderCount: client?.counters?.cancelled ?? 0,
					canceledOrderCountNoCarFound: "todo",

					firstName: client?.person?.firstName,
					lastName: client?.person?.lastName || "",
					fatherName: client?.person?.fatherName || "",
					birthday: client?.person?.birthday
						? localizeDate(new Date(client.person.birthday), "date")
						: "-",
					gender:
						t(`genders.${client.gender || "unknown"}`) || "Unknown",
					app: (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								pointerEvents: "none",
							}}
						>
							<CheckBox disabled value={client.isApp} />
						</div>
					),
					email:
						client?.person?.emails
							?.map((email) => email.value)
							.join(", ") || "-",
					phones:
						client?.person?.phones
							?.map((n) => n.number)
							.join(", ") || "",

					mainLoyaltyProgram: "todo",
					additionalLoyaltyProgram: "todo",

					bonusCard: "todo",
					tariff: "todo",
					defaultCarClass,

					status: t(`statuses.${client.status.level}`) || "",
					company,
					defaultTaxiService,
					latestTaxiService,

					notes: client.notes,
					orderNotes: client.orderNotes || "",
					executorNotes: client.executorNotes || "",

					group: client.customerGroup?.name,

					createdAt,
					updatedAt,
					deletedAt,
				};
			});
	}, [
		active,
		clients,
		companyLookup,
		language,
		taxiServiceLookup,
		carClassLookup,
		localizeDate,
		t,
	]);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);
	const [selected, setSelected] = useState<number[]>([]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const edit = useCallback(
		(id: number) => {
			const client = clientLookup[id].value;

			setEditingItem({
				id: client.id,

				firstName: client?.person?.firstName,
				lastName: client?.person?.lastName,
				fatherName: client?.person?.fatherName,

				email: client?.person?.emails?.[0]?.value ?? "",
				phones:
					client?.person?.phones?.map((phone) => phone.number) ?? [],
				gender: client.gender || "unknown",
				birthday: client?.person?.birthday
					? new Date(client.person.birthday)
					: null,
				paymentAccounts: client.paymentAccounts,

				carClassId: client.carClassId,

				status: client.status,
				companyId: client.companyId,
				defaultTaxiServiceId: client.defaultTaxiServiceId,
				latestTaxiServiceId: client.latestTaxiServiceId,
				mainRateId: client.mainRate?.id,
				additionalRateId: client.additionalRate?.id,

				notes: client.notes,
				orderNotes: client.orderNotes || "",
				executorNotes: client.executorNotes || "",

				smsNotifications: client.smsNotifications,

				isAllowedApp: client.isAllowedApp,
				isAllowedCabinet: client.isAllowedCabinet,
				login: client.login,
				hasPassword: client.hasPassword,
				password: client.password,

				additionalFields: client.additionalFields,

				personalFiles: client.personalFiles,
				otherFiles: client.otherFiles,
				customerGroupId: client.customerGroupId,

				createdAt: client.createdAt ?? undefined,
			});
		},
		[clientLookup],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() => selected.length !== 0,
		[selected.length],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			firstName: "",
			lastName: "",
			fatherName: "",
			birthday: null,
			gender: "unknown",
			paymentAccounts: [],

			email: "",
			phones: [""],

			status: {
				level: Client.Status.ACTIVE,
				description: "",
			},
			companyId: undefined,
			defaultTaxiServiceId: undefined,
			latestTaxiServiceId: undefined,
			customerGroupId: undefined,

			notes: "",
			orderNotes: "",
			executorNotes: "",
			smsNotifications: true,

			isAllowedApp: true,
			isAllowedCabinet: true,

			hasPassword: false,

			additionalFields: {},

			personalFiles: [],
			otherFiles: [],

			mainRateId: NaN,
			additionalRateId: NaN,

			paymentTransactions: {
				main: [],
				bonus: [],
			},
		});
	}, []);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const checkedClients = useMemo(() => {
		if (active === null) return [];

		const payload = [...(clients ?? [])];

		return [...payload].filter((item) => {
			if (active === undefined && selected.includes(item.id)) return true;
			if (
				item.status?.level === "active" &&
				active === true &&
				selected.includes(item.id)
			) {
				return true;
			}
			if (
				item.status?.level !== "active" &&
				active === false &&
				selected.includes(item.id)
			) {
				return true;
			}
			return false;
		});
	}, [clients, selected, active]);

	const content = useMemo(
		() => (
			<>
				<Header
					filters={headerFilters}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onChangeFilters={setHeaderFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
					clients={checkedClients}
					editorTable={editor}
					onChangeTable={onChange}
				/>
				<Content
					selected={selected}
					loading={false}
					data={tableData}
					setSelected={setSelected}
					onEdit={contentOnEdit}
					editorTable={editor}
					onChangeTable={onChange}
				/>
			</>
		),
		[
			headerFilters,
			headerCanEdit,
			headerCanDelete,
			headerOnAdd,
			headerOnEdit,
			headerOnDelete,
			checkedClients,
			editor,
			onChange,
			selected,
			tableData,
			contentOnEdit,
		],
	);

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated) => {
			let personalFileIds: number[] = [];
			let otherFileIds: number[] = [];

			if (typeof newItem.id === "number") {
				personalFileIds = newItem.personalFiles
					.filter((file) => file.id)
					.map((file) => file.id!);

				personalFileIds = personalFileIds.concat(
					(
						await Promise.all(
							newItem.personalFiles
								.filter((file) => !file.id)
								.map((file) => file.upload()),
						)
					)
						.filter(Boolean)
						.map((file) => file!.id!),
				);

				otherFileIds = newItem.otherFiles
					.filter((file) => file.id)
					.map((file) => file.id!);

				otherFileIds = otherFileIds.concat(
					(
						await Promise.all(
							newItem.otherFiles
								.filter((file) => !file.id)
								.map((file) => file.upload()),
						)
					)
						.filter(Boolean)
						.map((file) => file!.id!),
				);
			} else {
				personalFileIds = (
					await Promise.all(
						newItem.personalFiles.map((file) => file.upload()),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);

				otherFileIds = (
					await Promise.all(
						newItem.otherFiles.map((file) => file.upload()),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);
			}

			if (typeof newItem.id === "number") {
				await Client.update({
					id: newItem.id,
					// need to check if it is correct
					defaultTaxiServiceId: newItem.defaultTaxiServiceId,

					carClassId: newItem.carClassId,
					customerGroupId: newItem.customerGroupId,
					mainRateId: newItem.mainRateId,
					additionalRateId: newItem.additionalRateId,

					person: {
						firstName: newItem.firstName || "",
						lastName: newItem.lastName,
						fatherName: newItem.fatherName,

						birthday: newItem.birthday,
						emails: newItem.email ? [newItem.email] : [],
						phones: newItem.phones,
					},
					gender: newItem.gender,
					status: newItem.status,
					notes: newItem.notes,
					orderNotes: newItem.orderNotes || "",
					executorNotes: newItem.executorNotes || "",
					// customerToPriorityExecutors,
					// customerToUndesirableExecutors,
					// customerToForbiddenExecutors,

					isAllowedApp: newItem.isAllowedApp,
					isAllowedCabinet: newItem.isAllowedCabinet,
					smsNotifications: newItem.smsNotifications,

					login: newItem.login,
					password: newItem.password,

					additionalFields: {
						defaultAddress: newItem.additionalFields.defaultAddress,
					},

					personalFileIds,
					otherFileIds,
				});
			} else {
				await Client.store({
					mainRateId: newItem.mainRateId,
					additionalRateId: newItem.additionalRateId,
					companyId: newItem.companyId as number,
					defaultTaxiServiceId: newItem.defaultTaxiServiceId,
					customerGroupId: newItem.customerGroupId,

					carClassId: newItem.carClassId,

					person: {
						firstName: newItem.firstName,
						lastName: newItem.lastName,
						fatherName: newItem.fatherName,

						birthday: newItem.birthday,
						emails: newItem.email ? [newItem.email] : [],
						phones: newItem.phones.map((number) => number),
					},
					gender: newItem.gender,
					status: newItem.status,
					notes: newItem.notes,
					orderNotes: newItem.orderNotes || "",
					executorNotes: newItem.executorNotes || "",
					paymentTransactions: newItem.paymentTransactions as any,

					// customerToPriorityExecutors,
					// customerToUndesirableExecutors,
					// customerToForbiddenExecutors,

					isAllowedApp: newItem.isAllowedApp,
					isAllowedCabinet: newItem.isAllowedCabinet,
					smsNotifications: newItem.smsNotifications,

					login: newItem.login,
					password: newItem.password,

					additionalFields: {
						defaultAddress: newItem.additionalFields.defaultAddress,
					},

					personalFileIds,
					otherFileIds,
				});
			}

			setEditingItem(null);
		},
		[],
	);

	useEffect(() => {
		if (editingItem?.id) {
			const client = clientLookup[editingItem.id].value;
			const carClassId = client.carClassId || editingItem.carClassId;
			setEditingItem({
				...editingItem,
				paymentAccounts: client?.paymentAccounts ?? [],
				carClassId,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clients]);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);

		// await Client.destroy(selected);

		clients.forEach((item) => {
			if (selected.includes(item.id)) {
				Client.updateStatus({
					id: item.id,
					status: { level: Client.Status.NO_ACTIVE, description: "" },
				});
			}
		});

		setSelected([]);
	}, [clients, selected]);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const counters = useMemo(() => {
		if (editingItem?.id) {
			const exist = clients.find((item) => item.id === editingItem.id);
			if (exist) return exist.counters;
		}
		return {
			cancelled: 0,
			failed: 0,
			success: 0,
			total: 0,
		};
	}, [editingItem?.id, clients]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					groups={groups}
					value={editingItem}
					counters={counters}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
					label={
						t("pages.mainPage.pages.customers.tabs.clients.str0") ??
						""
					}
				/>
			)}
		</Root>
	);
};

export default Clients;
