import React, {
	// useCallback,
	useMemo,
} from "react";
// import { useTranslation } from "react-i18next";
// import { Icon } from "uikit";

// import SIP from "../../../../../../../../../../services/SIP";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../../../access";
// import Button from "../Button";
import DriverCall from "../../../../../OrderModal/components/Footer/components/DriverCall";

const CallExecutorButton: React.FC<CallExecutorButton.Props> = () => {
	const ACCESS_SECTION = AccessKey.ORDERS;
	const ACCESS_NAME = AccessKey.CALL_ORDER_EXECUTOR;

	// const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const sipToDispatcherId = useTypedSelector(
		(state) => state.sipToDispatcher.id,
	);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const executorPhones = useMemo<string[]>(() => {
		const executorToOrder = activeOrder?.executorToOrder?.at(0);
		if (!executorToOrder) return [];
		const { executor } = executorToOrder;
		return executor?.person?.phones?.map((phone) => phone.number) || [];
	}, [activeOrder?.executorToOrder]);

	const executorPhone = useMemo<string>(() => {
		if (!executorPhones.length) return "";
		return executorPhones?.[0] || "";
	}, [executorPhones]);

	const disabled = useMemo(() => {
		if (!activeOrder?.id) return true;
		if (!sipToDispatcherId) return true;
		if (!activeOrder.passengers?.length) return true;
		if (activeOrder.status === "closed") return true;
		if (!executorPhone) return true;

		const hasExecutor = activeOrder.executorToOrder.length;

		return !hasExecutor;
	}, [sipToDispatcherId, activeOrder, executorPhone]);

	// const onClick = useCallback(() => {
	// 	const executorToOrder = activeOrder?.executorToOrder?.at(0);

	// 	if (sipToDispatcherId && executorToOrder) {
	// 		const { executor } = executorToOrder;
	// 		const executorPhones =
	// 			executor?.person?.phones?.map((phone) => phone.number) || [];

	// 		const executorPhone = executorPhones?.[0];

	// 		if (executorPhone) {
	// 			SIP.callToPhone({
	// 				sipToDispatcherId,
	// 				phone: executorPhone,
	// 			});
	// 		}
	// 	}
	// }, [activeOrder, sipToDispatcherId]);

	const isNotAccess = useMemo(
		() =>
			!hasAccess(
				generateAccessName(ACCESS_SECTION, ACCESS_NAME),
				personalRole,
			),
		[ACCESS_SECTION, ACCESS_NAME, personalRole],
	);

	if (isNotAccess) return null;

	return (
		<DriverCall
			disabled={disabled}
			mainPhone={executorPhone}
			phones={executorPhones}
			showOnlyModal
			leftButtonStyled={{
				w: "28px",
				h: "28px",
			}}
			rightButtonStyled={{
				w: "17px",
				h: "28px",
			}}
		/>
		// <Button
		// 	variant="secondary"
		// 	disabled={disabled}
		// 	settingsKeyId="callOrderExecutor"
		// 	title={
		// 		t(
		// 			"pages.mainPage.pages.orders.orderHeader.secondRow.callExexcutorButton.str0",
		// 		) ?? ""
		// 	}
		// 	tabIndex={-1}
		// 	onClick={onClick}
		// >
		// 	<Icon id="orders-call-executor" size={20} />
		// </Button>
	);
};

declare namespace CallExecutorButton {
	interface Props {}
}

export default CallExecutorButton;
