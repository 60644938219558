import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { cloneDeep, isNil, isUndefined } from "lodash";

import Tariff from "../../../../services/Tariff";
import SMSTemplate from "../../../../services/SMSTemplate";
import Order from "../../../../services/Order";
import ChatMessage, { Priority } from "../../../../services/ChatMessage";
import PhoneBlacklist from "../../../../services/PhoneBlacklist";
import Executor from "../../../../services/Executor";
import { useTypedDispatch, useTypedSelector } from "../../../../redux/store";
import orderPage from "../../../../redux/reducers/OrdersPage";
import getCars from "../../../../redux/services/Car/getCars";
import getPolygons from "../../../../redux/services/Map/polygons";
import getServices from "../../../../redux/services/Preferences/Services/getServices";
import getClasses from "../../../../redux/services/Preferences/CarClass/getClasses";
import openOrderCard from "../../../../redux/services/Order/card/openOrderCard";
import initOrderCard from "../../../../redux/services/Order/card/initOrderCard";
import closeOrderCard from "../../../../redux/services/Order/card/closeOrderCard";
import loadCarsPositions from "../../../../redux/services/Order/getCarsPositions";
import setRates from "../../../../redux/actions/Preferences/Rates/setRates";
import { ordersAction as orders } from "../../../../redux/reducers/Orders";
import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import { MessageTemplateActions } from "../../../../types/MessageTemplateEnums";
import OrderStatus from "../../../../types/OrderStatus";
import ContextMenu from "../../../../components/OrderPageWidgets/Chat/components/ChatList/components/ContextMenu";
import DeleteModal from "../../../../components/DeleteModal";
import { SOSModal } from "../../../../components/Orders";
import { StyledGrid } from "../../../../components/common";
import { hasAccess } from "../../../../access";

import useOrderTracking from "./hooks/useOrderTracking";
import HeaderOrder from "./components/OrderHeader/OrderHeaderPage";
import MainOrderPage from "./components/MainOrderPage";
import OrderModal from "./components/OrderModal";
import SetOrderExecutorModal from "./components/SetOrderExecutorModal";
import CloseOrderModal from "./components/CloseOrderModal";
import CopyOrderModal from "./components/CopyOrderModal";
import ClientCancelOrderModal from "./components/ClientCancelOrderModal";
import { CloseReason } from "./components/CloseOrderModal/components/Content";
import AddClientToBlacklistModal from "./components/AddClientToBlacklistModal";
import {
	TabKeys,
	TabAccessNames,
} from "./components/OrderHeader/components/FirstRow/constants/access";

export const ORDER_SMS_TEMPLATES = [
	MessageTemplateActions.WILL_BE_TIME,
	MessageTemplateActions.OUTPUT_PASSENGERS,
	MessageTemplateActions.LATE,
	MessageTemplateActions.CAR_NOT_FOUND,
	MessageTemplateActions.ORDER_SUCCESSFULLY_COMPLETED,
	MessageTemplateActions.WITH_PASSENGERS,
	MessageTemplateActions.DRIVER_SWITCHED,
];

const StyledMainPage = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
`;

export default function OrdersPage() {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();

	const ratesSubscription = useModelSubscribe({}, Tariff);

	const lang = useTypedSelector((state) => state.session.language);
	const orderType = useTypedSelector(
		(state) => state.ordersPageReducer.ordersType,
	);
	const orderSettings = useTypedSelector((state) => state.settings.order);
	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);
	const modal = useTypedSelector((state) => state.ordersPageReducer.modal);
	const { booferAnswerParentID, contextMenuCoordinate, isShowContextMenu } =
		useTypedSelector((state) => state.orders.chats.chat);
	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const subscribeChatMessage = useModelSubscribe(
		{
			lang,
			priority: Priority.SOS,
			unreadMessages: true,
		},
		ChatMessage,
	);

	const smsTemplatesSubscription = useModelSubscribe(
		{
			actions: ORDER_SMS_TEMPLATES,
			order: { action: "ASC" },
		} as any,
		SMSTemplate,
	);

	const [closeOrderModalValue, setCloseOrderModalValue] =
		useState<CloseOrderModal.Value | null>(null);

	const [isWarnBeforeCloseModalOpen, setIsWarnBeforeCloseModalOpen] =
		useState(false);
	const [isCloseOrderModalOpen, setIsCloseOrderModalOpen] = useState(false);
	const [isClientCancelOrderModalOpen, setIsClientCancelOrderModalOpen] =
		useState(false);
	const [
		isAddClientToBlackListModalOpen,
		setIsAddClientToBlackListModalOpen,
	] = useState(false);
	const [isCloneOrderModalOpen, setIsCloneOrderModalOpen] = useState(false);
	const [isSetOrderExecutorModalOpen, setIsSetOrderExecutorModalOpen] =
		useState(false);
	const [isRefuseOrderModalOpen, setIsRefuseOrderModalOpen] = useState(false);

	const [activePoint, setActivePoint] = useState(-1);
	const [pointModal, setPointModal] = useState({
		visible: false,
		isEdit: false,
		isInsert: false,
	});
	const [showSOSModal, setShowSOSModal] = useState<boolean>(false);

	useOrderTracking();

	const closeCards = React.useCallback(
		(ids: any[] = []) => {
			dispatch(closeOrderCard(...ids));
		},
		[dispatch],
	);

	const blockClient = useCallback(
		(value: AddClientToBlacklistModal.Value) => {
			const { customer } = activeOrder?.passengers?.[0] || {};
			const [customerPhone] = activeOrder.phones || [];

			PhoneBlacklist.store({
				level: value.level,
				description: value.comment ?? "",
				phone: customerPhone?.number,
				firstName: customer?.person?.name || " ",
				lastName: customer?.person?.surname || " ",
				fatherName: customer?.person?.fatherName || " ",
				companyIds: [activeOrder?.taxiService?.company?.id],
			});
		},
		[
			activeOrder?.passengers,
			activeOrder?.phones,
			activeOrder?.taxiService?.company?.id,
		],
	);

	const closeContextMenu = useCallback(() => {
		dispatch(orders.chats.chat.setIsShowContextMenu(false));
	}, [dispatch]);

	const openCloseOrderModal = useCallback(() => {
		if (!activeOrder.id) return;

		if (orderSettings.general.warnWhenClosingZeroCostOrder) {
			const orderCost: number =
				(activeOrder.cost?.raw ?? 0) +
				(activeOrder.additionalFields?.additionalCost ?? 0);

			if (orderCost === 0) {
				setIsWarnBeforeCloseModalOpen(true);

				return;
			}
		}

		if (
			orderSettings.general.warnWhenClosingNoDistanceOrder &&
			(activeOrder?.route?.distance ?? 0) / 1000 +
				(activeOrder?.additionalFields?.outsideSettlementKm ?? 0) ===
				0
		) {
			setIsWarnBeforeCloseModalOpen(true);

			return;
		}

		if (
			orderSettings.general.warnWhenClosingOwnOrder &&
			activeOrder.source === "executor"
		) {
			setIsWarnBeforeCloseModalOpen(true);

			return;
		}

		setIsCloseOrderModalOpen(true);
	}, [
		activeOrder.additionalFields?.additionalCost,
		activeOrder.additionalFields?.outsideSettlementKm,
		activeOrder.cost?.raw,
		activeOrder.id,
		activeOrder?.route?.distance,
		activeOrder.source,
		orderSettings.general.warnWhenClosingNoDistanceOrder,
		orderSettings.general.warnWhenClosingOwnOrder,
		orderSettings.general.warnWhenClosingZeroCostOrder,
	]);

	const submitCloseOrderModal = useCallback(
		(value: CloseOrderModal.Value) => {
			setIsCloseOrderModalOpen(false);

			if (!activeOrder.id) return;

			if (
				value.reason === CloseReason.ClientCanceled ||
				value.addClientToBlackList
			) {
				setCloseOrderModalValue(value);

				if (value.reason === CloseReason.ClientCanceled) {
					setIsClientCancelOrderModalOpen(true);
				} else {
					setIsAddClientToBlackListModalOpen(true);
				}
			} else {
				Order.close(activeOrder.id, {
					status: value.reason,
				});
			}
		},
		[activeOrder.id],
	);

	const closeCloseOrderModal = useCallback(() => {
		setIsCloseOrderModalOpen(false);
	}, []);

	const closeClientCancelOrderModal = useCallback(() => {
		setIsClientCancelOrderModalOpen(false);
		setCloseOrderModalValue(null);
	}, []);

	const submitClientCancelOrderModal = useCallback(
		(value: ClientCancelOrderModal.Value) => {
			if (
				closeOrderModalValue?.addClientToBlackList &&
				!value.blockComment
			) {
				return;
			}

			setIsClientCancelOrderModalOpen(false);
			setCloseOrderModalValue(null);

			Order.close(activeOrder.id, {
				status: closeOrderModalValue?.reason,
				subStatus: value.reason,
				comment: value.comment,
			});

			if (closeOrderModalValue?.addClientToBlackList) {
				blockClient({
					level: value.blockLevel,
					comment: value.blockComment,
				});
			}
		},
		[
			activeOrder.id,
			blockClient,
			closeOrderModalValue?.addClientToBlackList,
			closeOrderModalValue?.reason,
		],
	);

	const closeAddClientToBlacklistModal = useCallback(() => {
		setIsAddClientToBlackListModalOpen(false);
		setCloseOrderModalValue(null);
	}, []);

	const submitAddClientToBlacklistModal = useCallback(
		(value: AddClientToBlacklistModal.Value) => {
			if (!value.comment) return;

			setIsAddClientToBlackListModalOpen(false);
			setCloseOrderModalValue(null);

			Order.close(activeOrder.id, {
				status: closeOrderModalValue?.reason,
			});

			blockClient(value);
		},
		[activeOrder.id, blockClient, closeOrderModalValue?.reason],
	);

	const openCloneOrderModal = useCallback(() => {
		setIsCloneOrderModalOpen(true);
	}, []);

	const closeCloneOrderModal = useCallback(() => {
		setIsCloneOrderModalOpen(false);
	}, []);

	const cloneOrderModalOnSubmit = useCallback(
		async (value: CopyOrderModal.Value) => {
			if (activeOrder.id) Order.copy(activeOrder.id, value);

			setIsCloneOrderModalOpen(false);
		},
		[activeOrder.id],
	);

	const openSetOrderExecutorModal = useCallback(() => {
		setIsSetOrderExecutorModalOpen(true);
	}, []);

	const closeSetOrderExecutorModal = useCallback(() => {
		setIsSetOrderExecutorModalOpen(false);
	}, []);

	const setOrderExecutorModalOnSubmit = useCallback(
		async (value: SetOrderExecutorModal.Value) => {
			if (isUndefined(value.executor)) return;

			if (!value.executor.isWorking && value.openShift) {
				await Executor.updateWorkingStatus(
					{
						id: value.executor.id,
						workingStatus: true,
					},
					value.carId ?? null,
				);
			}

			if (activeOrder.id) {
				const arrivalTime = Date.now() + value.arrivalTime * 60_000;

				const result = await Order.assign(activeOrder.id, {
					executorId: value.executor.id,
					arrivalTime,
				});

				if (!result.error) {
					setIsSetOrderExecutorModalOpen(false);
				}
			} else {
				setIsSetOrderExecutorModalOpen(false);
			}
		},
		[activeOrder.id],
	);

	const approveOrder = useCallback(() => {
		if (isUndefined(activeOrder.id) || isNil(activeOrder.offer?.executor))
			return;

		Order.accept(activeOrder.id);
	}, [activeOrder]);

	const recalculateOrder = useCallback(() => {
		if (isUndefined(activeOrder.id) || orderType === "closed") return;

		Order.recalculate(activeOrder.id);
	}, [activeOrder.id, orderType]);

	const recalculateOrderWithActualRates = useCallback(() => {
		if (isUndefined(activeOrder.id) || orderType === "closed") return;

		Order.recalculate(activeOrder.id, { useActualRates: true });
	}, [activeOrder.id, orderType]);

	const refuseOrder = useCallback(() => {
		if (isUndefined(activeOrder.id)) return;

		setIsRefuseOrderModalOpen(true);
	}, [activeOrder]);

	const closeRefuseOrderModal = useCallback(() => {
		setIsRefuseOrderModalOpen(false);
	}, []);

	const confirmRefuseOrderModal = useCallback(() => {
		setIsRefuseOrderModalOpen(false);

		if (isUndefined(activeOrder.id)) return;

		Order.refuse(activeOrder.id);
	}, [activeOrder.id]);

	const sendOrderToAir = useCallback(() => {
		if (isUndefined(activeOrder.id)) return;

		Order.startBroadcasting(activeOrder.id);
	}, [activeOrder.id]);

	const toggleOrderAir = useCallback(() => {
		if (isUndefined(activeOrder.id)) return;

		if (activeOrder.status === OrderStatus.FREE_WAVE)
			Order.stopBroadcasting(activeOrder.id);
		else Order.startBroadcasting(activeOrder.id);
	}, [activeOrder.id, activeOrder.status]);

	const toggleShowClientPhoneToExecutor = useCallback(() => {
		if (isUndefined(activeOrder.id)) return;

		const additionalFields = cloneDeep(activeOrder.additionalFields);

		additionalFields.displaySettings =
			additionalFields.displaySettings ?? {};
		additionalFields.displaySettings.showCustomerPhone =
			!additionalFields.displaySettings.showCustomerPhone;

		Order.update({ id: activeOrder.id, additionalFields });
	}, [activeOrder]);

	const setCallToClientBeingRequired = useCallback(() => {
		if (isUndefined(activeOrder.id)) return;

		Order.updateCallStatus(activeOrder.id, {
			type: "customer",
			status: "need_call",
		});
	}, [activeOrder]);

	const setCallToClientSuccess = useCallback(() => {
		if (isUndefined(activeOrder.id)) return;

		Order.updateCallStatus(activeOrder.id, {
			type: "customer",
			status: "called",
		});
	}, [activeOrder]);

	const setCallToExecutorBeingRequired = useCallback(() => {
		if (isUndefined(activeOrder.id)) return;

		Order.updateCallStatus(activeOrder.id, {
			type: "executor",
			status: "need_call",
		});
	}, [activeOrder]);

	const setCallToExecutorSuccess = useCallback(() => {
		if (isUndefined(activeOrder.id)) return;

		Order.updateCallStatus(activeOrder.id, {
			type: "executor",
			status: "called",
		});
	}, [activeOrder]);

	const onRevertOrder = useCallback(() => {
		if (isUndefined(activeOrder.id)) return;
		Order.revert(activeOrder.id);
	}, [activeOrder?.id]);

	const sosMessageModel = useMemo(() => {
		if (
			!subscribeChatMessage.models ||
			!subscribeChatMessage.models?.length
		) {
			return [];
		}

		return subscribeChatMessage.models.filter(
			(item) => item.priority === Priority.SOS,
		);
	}, [subscribeChatMessage]);

	useLayoutEffect(() => {
		if (sosMessageModel.length) setShowSOSModal(true);
	}, [sosMessageModel]);

	const handleSOSModal = useCallback(() => {
		sosMessageModel.forEach((item) => {
			ChatMessage.readSosMessageByDispatcher(item.id);
		});
		setShowSOSModal(false);
	}, [sosMessageModel]);

	useEffect(() => {
		dispatch(setRates(ratesSubscription.models));
	}, [dispatch, ratesSubscription.models]);

	useEffect(() => {
		dispatch(
			orderPage.actions.setSMSTemplates(smsTemplatesSubscription.models),
		);
	}, [dispatch, smsTemplatesSubscription.models]);

	useEffect(() => {
		dispatch(initOrderCard());
		dispatch(getPolygons());
		dispatch(getCars());
		dispatch(getServices());
		dispatch(getClasses());
	}, [dispatch]);

	useEffect(() => {
		const time = setInterval(() => {
			dispatch(loadCarsPositions());
			// TODO: take interval from settings
		}, 4000);

		return () => {
			clearInterval(time);
		};
	}, [dispatch]);

	const carClasses = useMemo(() => {
		if (
			!isSetOrderExecutorModalOpen &&
			!activeOrder?.id &&
			!activeOrder?.orderToCarClasses
		) {
			return [];
		}

		return activeOrder.orderToCarClasses.map(({ carClass }) => carClass);
	}, [
		activeOrder?.id,
		activeOrder?.orderToCarClasses,
		isSetOrderExecutorModalOpen,
	]);

	const services = useMemo(() => {
		if (
			!isSetOrderExecutorModalOpen &&
			!activeOrder?.id &&
			!activeOrder?.orderToServices
		) {
			return [];
		}

		return activeOrder.orderToServices?.map(({ service }) => service) || [];
	}, [
		activeOrder?.id,
		activeOrder?.orderToServices,
		isSetOrderExecutorModalOpen,
	]);

	const isShowMainOrderPage = !![
		TabAccessNames[TabKeys.LIVE],
		TabAccessNames[TabKeys.PRELIMINARY],
		TabAccessNames[TabKeys.EXECUTING],
		TabAccessNames[TabKeys.ALL],
		TabAccessNames[TabKeys.CLOSED],
	].filter((tab) => hasAccess(tab, personalRole)).length;

	return (
		<>
			<StyledGrid
				areas=""
				columns={{ column: "auto" }}
				rows={{ row: "1fr", rows: "85px" }}
			>
				{modal.open && (
					<OrderModal
						activePoint={activePoint}
						setActivePoint={setActivePoint}
						close={closeCards}
						pointModal={pointModal}
						setPointModal={setPointModal}
					/>
				)}
				<HeaderOrder
					onRevertOrderClick={onRevertOrder}
					onOpenEditOrderModal={() => {
						if (activeOrder?.id) {
							dispatch(openOrderCard(activeOrder.id));
						}
					}}
					toggleModalStatus={() => {
						dispatch(openOrderCard());
						dispatch(orderPage.actions.setRoute([]));
					}}
					onCloseOrderClick={openCloseOrderModal}
					onCloneOrderClick={openCloneOrderModal}
					onSetOrderExecutorClick={openSetOrderExecutorModal}
					onApproveOrderClick={approveOrder}
					onRecalculateOrderClick={recalculateOrder}
					onRecalculateOrderWithActualRatesClick={
						recalculateOrderWithActualRates
					}
					onRefuseOrderClick={refuseOrder}
					onSendOrderToAirClick={sendOrderToAir}
					onToggleOrderAirClick={toggleOrderAir}
					onSetCallToClientBeingRequiredClick={
						setCallToClientBeingRequired
					}
					onSetCallToClientSuccessClick={setCallToClientSuccess}
					onSetCallToExecutorBeingRequiredClick={
						setCallToExecutorBeingRequired
					}
					onSetCallToExecutorSuccessClick={setCallToExecutorSuccess}
					onShowClientNumberToExecutorClick={
						toggleShowClientPhoneToExecutor
					}
				/>

				{isShowMainOrderPage && (
					<StyledMainPage>
						<MainOrderPage />
					</StyledMainPage>
				)}

				{isShowContextMenu && (
					<ContextMenu
						coordinate={contextMenuCoordinate}
						onClose={closeContextMenu}
						booferAnswerParentID={booferAnswerParentID}
					/>
				)}

				{isWarnBeforeCloseModalOpen && (
					<DeleteModal
						label={t("pages.mainPage.pages.orders.str200") ?? ""}
						onCancel={() => {
							setIsWarnBeforeCloseModalOpen(false);
						}}
						onConfirm={() => {
							setIsWarnBeforeCloseModalOpen(false);
							setIsCloseOrderModalOpen(true);
						}}
					/>
				)}

				{isCloseOrderModalOpen && activeOrder.id && (
					<CloseOrderModal
						onClose={closeCloseOrderModal}
						onSubmit={submitCloseOrderModal}
					/>
				)}

				{isClientCancelOrderModalOpen && activeOrder.id && (
					<ClientCancelOrderModal
						addClientToBlackList={
							closeOrderModalValue?.addClientToBlackList ?? false
						}
						onClose={closeClientCancelOrderModal}
						onSubmit={submitClientCancelOrderModal}
					/>
				)}

				{isAddClientToBlackListModalOpen && activeOrder.id && (
					<AddClientToBlacklistModal
						onClose={closeAddClientToBlacklistModal}
						onSubmit={submitAddClientToBlacklistModal}
					/>
				)}

				{isCloneOrderModalOpen && activeOrder.id && (
					<CopyOrderModal
						onClose={closeCloneOrderModal}
						onSubmit={cloneOrderModalOnSubmit}
					/>
				)}
				{isSetOrderExecutorModalOpen && activeOrder.id && (
					<SetOrderExecutorModal
						orderTime={
							activeOrder?.isPreliminary
								? activeOrder?.orderDate
								: undefined
						}
						onClose={closeSetOrderExecutorModal}
						onSubmit={setOrderExecutorModalOnSubmit}
						carClasses={carClasses}
						services={services}
					/>
				)}
				{isRefuseOrderModalOpen && (
					<DeleteModal
						label={t("pages.mainPage.pages.orders.str201") ?? ""}
						onCancel={closeRefuseOrderModal}
						onConfirm={confirmRefuseOrderModal}
					/>
				)}
			</StyledGrid>
			{showSOSModal && (
				<SOSModal
					value={{ messages: sosMessageModel }}
					onClose={handleSOSModal}
					onSave={handleSOSModal}
				/>
			)}
		</>
	);
}
